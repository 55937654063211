const storage = {
    2: "HeadHunter",
    1: "Tap HR",
    unknown: "ИсточникНеизвестен",
};

/**
 * Gets the source name for a given source ID.
 *
 * @param {Object} sourceCounter - Object that tracks occurrences of each source.
 * @param {Number} sourceId - Source ID to retrieve the name for.
 * @returns {String} - Formatted source name with occurrence count (if greater than 1).
 */
export const getSourceName = (sourceCounter, sourceId) => {
    // Ensure sourceId is valid; default to "unknown" if not found
    const validSourceId = storage[sourceId] ? sourceId : "unknown";

    // Update occurrence counter for the sourceId
    sourceCounter[validSourceId] = (sourceCounter[validSourceId] || 0) + 1;

    // Generate the source name with occurrence count if applicable
    const count = sourceCounter[validSourceId];
    return `${storage[validSourceId]}${count > 1 ? ` ${count}` : ""}`;
};
