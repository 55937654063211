import React, { useState } from 'react';
import { Container, Typography, Box, Select, MenuItem, Paper, Pagination } from '@mui/material';
import FilterBar from '../../components/vacancy/FilterBar';
import VacancyTable from '../../components/vacancy/VacancyTable';
import CreateVacancyModal from '../../components/vacancy/CreateVacancyModal';
import { useQuery } from '@tanstack/react-query';
import { fetchVacancies } from '../../services/vacancyApi';

const VacanciesPage = () => {
    const [rowsPerPage, setRowsPerPage] = useState(10); // Количество строк на странице
    const [currentPage, setCurrentPage] = useState(1); // Текущая страница
    const [open, setOpen] = useState(false); // Состояние модального окна

    // Фетч вакансий с учётом текущей страницы и количества строк на странице
    const { data, isLoading, isError } = useQuery(['vacancies', currentPage, rowsPerPage], () =>
        fetchVacancies(currentPage, rowsPerPage)
    );

    // Обработчик изменения количества строк на странице
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setCurrentPage(1); // Сброс на первую страницу
    };

    // Обработчик изменения текущей страницы
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container maxWidth="none" sx={{ mt: 4, display: 'flex', flexDirection: 'column' }}>
            <Paper
                elevation={0}
                sx={{
                    flex: 1,
                    p: 1,
                    borderRadius: 2,
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #e0e0e0',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 'calc(90vh - 25px)',
                }}
            >
                {/* Фильтр */}
                <FilterBar label={"Создать вакансию"} hiddenSelects handleOpen={handleClickOpen} />

                {/* Таблица */}
                <Box sx={{ flex: 1, overflowY: 'auto', padding: '0' }}>
                    {isLoading ? (
                        <Typography>Загрузка...</Typography>
                    ) : isError ? (
                        <Typography color="error">Ошибка при загрузке вакансий</Typography>
                    ) : (
                        <VacancyTable vacancies={data.data} />
                    )}

                </Box>

                {/* Пагинация и выбор количества строк */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ color: '#A2A1A8', fontWeight: '300' }}>
                            Показать
                        </Typography>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            sx={{
                                mx: 1,
                                color: '#6E6E6E',
                                borderColor: '#E0E0E0',
                                height: '46px',
                                width: '76px',
                            }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                        <Typography variant="body2" sx={{ color: '#A2A1A8', fontWeight: 300 }}>
                            результатов на странице
                        </Typography>
                    </Box>
                    <Pagination
                        count={data?.meta?.last_page || 1} // Количество страниц
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        sx={{ button: { color: '#6E6E6E', borderColor: '#E0E0E0' } }}
                    />
                </Box>
            </Paper>

            {/* Модальное окно */}
            <CreateVacancyModal open={open} handleClose={handleClose} />
        </Container>
    );
};

export default VacanciesPage;
