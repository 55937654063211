
export const navigateToHH = () => {
    // const currentUrl = window.location.origin + window.location.pathname;
    console.log(localStorage.getItem('manager_id'))
    console.log(localStorage.getItem('is_hh_connected'))
    if (localStorage.getItem('manager_id') && localStorage.getItem('is_hh_connected') === 'false') {
        const currentUrl = 'https://app.taphr.kz' + window.location.pathname + `?manager_id=${localStorage.getItem('manager_id')}`;
        const encodedRedirectUri = encodeURIComponent(`${currentUrl}`);
        console.log(currentUrl)
        console.log(encodedRedirectUri)
        window.location.href = `https://hh.ru/oauth/authorize?response_type=code&client_id=${localStorage.getItem('client_id')}&redirect_uri=${encodedRedirectUri}`;
    }
}