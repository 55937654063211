// src/services/vacancyApi.js

// Импортируйте axiosClassic из конфигурации
import axiosClassic from "../api/interceptors";

// Получение списка вакансий
export const fetchVacancies = async (page = 1, perPage = 10) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.get(`/api/company/${companyId}/vacancies`, {
        params: { page, per_page: perPage },
    });

    return {
        data: response.data.data, // Список вакансий
        meta: response.data.meta, // Данные для пагинации
    };
};


// Получение конкретной вакансии по ID
export const fetchVacancyById = async (vacancyId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.get(`/api/company/${companyId}/vacancies/${vacancyId}`);
    return response.data.data;
};

export const fetchVacancyByIdOnEdit = async (vacancyId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.get(`/api/company/${companyId}/vacancies/${vacancyId}?without_stages=1`);
    return response.data.data;
};

// Создание новой вакансии вручную
export const createVacancy = async (vacancyData) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.post(`/api/company/${companyId}/vacancies`, vacancyData);
    return response.data.data;
};

// Создание вакансии через внешний источник (например, HeadHunter)
export const createVacancyFromExternal = async (externalUrl) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.post(`/api/company/${companyId}/vacancies/from-external`, {
        external_url: externalUrl,
    });
    return response.data.data; // Предполагаем, что в `data.data` приходит созданная вакансия
};

// Обновление вакансии по ID
export const updateVacancy = async (vacancyId, vacancyData) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.put(
        `/api/company/${companyId}/vacancies/${vacancyId}`,
        vacancyData
    );

    return response.data.data; // Возвращаем обновлённые данные вакансии
};

