function isNumeric(value) {
    return Number.isFinite(value);
}


/**
 * Adds two numbers and returns the result.
 * @param {Object} result - result object from react-beautiful-dnd
 * @param {Function} handleStageChange - function to handle stage change
 * @param {Boolean} byColumn - if true, then drag and drop is by column, else by candidate
 * @returns {void} - returns nothing, but provides columnStartIdx, columnEndIdx, elementStartIdx, elementEndIdx to handleStageChange
 */
export const onDragEnd = (result, handleStageChange, byColumn) => {

    const { source, destination } = result;

    const columnStartIdx = source ? Number(source.droppableId) :null;
    const columnEndIdx = destination ? Number(destination.droppableId) : null;
    const elementStartIdx = source ? Number(source.index) : null;
    const elementEndIdx = destination ? Number(destination.index) : null;

    if (!isNumeric(columnEndIdx) || !isNumeric(columnStartIdx) || !isNumeric(elementStartIdx) || !isNumeric(elementEndIdx)) {
        return;
    }

    // check if dragged into other place than source, if byColumn then columns must be different, else element indexes must be different
    const isTheSamePlace = byColumn ? columnStartIdx === columnEndIdx : elementStartIdx === elementEndIdx;

    if (isTheSamePlace) {
        return;
    }

    handleStageChange({columnStartIdx, columnEndIdx, elementStartIdx, elementEndIdx});
};