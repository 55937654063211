import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Select,
    MenuItem,
} from '@mui/material';
import { WarningIcon, CloseIcon } from '../svgComponents';
import { useMutation } from '@tanstack/react-query';
import { discardCandidate } from '../../services/candidatesApi';


const RefusalModal = ({ open, onClose, reasons, companyId, candidateId, onSuccess }) => {
    const [selectedReason, setSelectedReason] = useState('');

    // Создаем mutation для отказа
    const mutation = useMutation({
        mutationFn: discardCandidate,
        onSuccess: () => {
            onSuccess(); // Вызов callback после успешного выполнения
            onClose(); // Закрыть модалку
        },
        onError: (error) => {
            console.error('Ошибка при отказе кандидату:', error);
        },
    });


    const handleSelectChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleSubmit = () => {
        console.log(companyId, candidateId)
        if (selectedReason) {
            mutation.mutate({
                companyId,
                candidateId,
                reason: selectedReason,
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '12px',
                    padding: '10px',
                    position: 'relative',
                    width: '404px'
                },
            }}
        >
            <WarningIcon 
                sx={{
                    position: 'absolute',
                    top: '14px',
                    left: '30px',
                    width: "48px",
                    height: "48px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(254, 228, 226, 1)",
                    border: "8px solid rgba(254, 243, 242, 1)",
                }}
                svgSx ={{
                    width: "20px",
                    height: "20px",
                    color: "rgba(217, 45, 32, 1)",
                }}
            />
            <CloseIcon 
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: '24px',
                    right: '34px',
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                }}
                svgSx ={{
                    width: "16px",
                    height: "16px",
                    color: "rgba(0, 0, 0, 0.5)",
                    hoverColor: "rgba(0, 0, 0, 0.75)",
                    pressedColor: "rgba(0, 0, 0, 1)"
                }}
            />
            <DialogContent
                sx={{
                    textAlign: 'left',
                    paddingTop: '96px',
                    paddingBottom: '24px',

                }}
            >
                <Typography
                    component="span"
                    sx={{
                        color: '#151813',
                        fontSize: '20px',
                        fontWeight: 600
                    }}
                >
                    Причина отказа
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginBottom: '16px',
                        color: '#747473',
                        marginTop: '8px'
                    }}
                >
                    Для того, чтобы отправить отказ кандидату, выберите, пожалуйста, причину отказа
                </Typography>
                <Select
                    value={selectedReason}
                    onChange={handleSelectChange}
                    displayEmpty
                    fullWidth
                    sx={{
                        backgroundColor: '#F8F9F9',
                        borderRadius: '8px',
                        height: '48px',
                        fontSize: '16px',
                        padding: '4px',
                        border: 'none',
                        outline: 'none'
                    }}
                >
                    <MenuItem value="" disabled>
                        Выберите причину отказа
                    </MenuItem>
                    {reasons.map((reason, index) => (
                        <MenuItem key={index} value={reason.id}>
                            {reason.name}
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'space-between',
                    padding: '0 24px 16px',
                }}
            >
                <Button
                    onClick={onClose}
                    variant="outlined"
                    sx={{
                        fontWeight: 500,
                        fontSize: '14px',
                        color: '#6C757D',
                        borderColor: '#E2E4E7',
                        borderRadius: '8px',
                        width: '48%',
                        height: '46px',
                    }}
                >
                    Отменить
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                        fontWeight: 500,
                        fontSize: '14px',
                        color: '#FFFFFF',
                        backgroundColor: '#FF3B30',
                        borderRadius: '8px',
                        width: '48%',
                        height: '46px',
                        '&:hover': {
                            backgroundColor: '#B71C1C',
                        },
                    }}
                    disabled={!selectedReason}
                >
                    Подтвердить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RefusalModal;
