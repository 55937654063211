import React, { useState } from 'react';
import { Box, Container, Typography, Tabs, Tab } from '@mui/material';
import PossibleIntegrations from '../../components/integrations/possibleIntegrations';

const TabPanel = ({ children, value, index }) => {
    return (
        <div hidden={value !== index} role="tabpanel">
            {children && value === index && <Box sx={{ paddingTop: "20px" }}>{children}</Box>}
        </div>
    );
};

const TabLabel = ({ text, activeMainTab, index }) => {
    return (
        <Typography
            variant="body2"
            noWrap 
            sx={{ color: activeMainTab === index ? "#599D15" : "#ADB5BD" }}
        >
            {text}
        </Typography>
    )
}

const IntegrationsPage = () => {

    const [activeMainTab, setActiveMainTab] = useState(0);

    const handleMainTabChange = (event, newValue) => {
        setActiveMainTab(newValue);
    }

    return (
        <Container maxWidth="none" sx={{ margin: '24px 0', display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    backgroundColor: 'white',
                    height: 'calc(90vh - 48px)',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '24px',
                    gap: '36px',
                }}
            >
                {/* Tabs */}
                <Box sx={{ borderBottom: 1, borderColor: 'rgba(226, 228, 231, 1)' }}>
                    <Typography sx={{mb: "16px", fontWeight: 600, fontSize: "24px", lineHeight: "28px"}} >
                        Интеграции
                    </Typography>
                    <Tabs 
                        value={activeMainTab}
                        onChange={handleMainTabChange}
                        TabIndicatorProps={{
                            style: { backgroundColor: '#599D15', height: '3px', borderRadius: "100px 100px 0 0" },
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            '& .MuiTab-root': {
                                maxWidth: 135,
                                textTransform: 'none',
                                padding: "0 8px",
                            },
                        }}
                    >
                        <Tab label={<TabLabel text={"Джоб сайты"} activeMainTab={activeMainTab} index={0} />} />
                        <Tab label={<TabLabel text={"Процессы"} activeMainTab={activeMainTab} index={1} />} />
                        <Tab label={<TabLabel text={"Карьерный сайт"} activeMainTab={activeMainTab} index={2} />} />
                    </Tabs>
                </Box>

                {/* Tab Content */}
                <TabPanel value={activeMainTab} index={0}>
                    <PossibleIntegrations />
                </TabPanel>
            </Box>
        </Container>
    );
};

export default IntegrationsPage;
