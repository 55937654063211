import React, { useEffect, useState } from 'react';
import { Container, Checkbox, FormControlLabel, Typography, Box, IconButton, Select, MenuItem, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import { ReactComponent as NotificationIcon } from '../../assets/icons/notificationIcon.svg';
import vacancyIcon from "../../assets/icons/vacancyIcon.svg";
import { useQuery } from '@tanstack/react-query';
import axiosClassic from '../../api/interceptors';
import { getMe } from '../../services/main';
import { logout } from '../../services/authApi'; // Импортируем функцию logout
import { closeHHNotify } from '../../services/hhpublicationApi';
import { navigateToHH } from "../../utils/connections"

const CustomArrowIcon = () => (
    <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00012 10L12.0001 14L17.0001 10" stroke="#151813" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);


const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { data, isLoading, isError, refetch } = useQuery(['getMe'], getMe);

    const sendAuthRequest = async (code, redirectUri, managerId) => {
        try {
            const response = await axiosClassic.post('https://api.taphr.kz/api/hh/auth', {
                code,
                redirect_uri: redirectUri,
                manager_id: managerId,
            });
            console.log('Запрос hh/auth успешно отправлен:', response.data);

            // Повторный запрос getMe после успешного выполнения hh/auth
            refetch();
        } catch (error) {
            console.error('Ошибка при выполнении запроса hh/auth:', error);
        }
    };


    useEffect(() => {
        if (data?.data) {
            const currentCompany = data.data.companies.find(
                company => company.company_id == localStorage.getItem('company_id')
            );
            if (currentCompany && !currentCompany.is_hh_notified && !currentCompany.is_hh_connected) {
                setIsModalOpen(true);
            } else {
                setIsModalOpen(false);
            }

            localStorage.setItem('is_hh_connected', currentCompany?.is_hh_connected);
        }
    }, [data]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const code = params.get('code');
        const managerId = location.search.split('manager_id=')[1]?.split('&')[0];
        const redirectUri = `https://app.taphr.kz${location.pathname}?manager_id=${managerId}`;

        if (code && managerId) {
            // Отправляем запрос hh/auth
            sendAuthRequest(code, redirectUri, managerId);
        }
    }, [location]);


    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (isError) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <Typography color="error">Ошибка загрузки данных пользователя</Typography>
            </Box>
        );
    }

    localStorage.setItem('client_id', data?.data.hh_client_id)
    const userName = data?.data.name || 'Имя пользователя';
    const companyId = localStorage.getItem('company_id');
    const currentCompany = data?.data?.companies.find(company => company.company_id == companyId);
    const companyName = currentCompany?.company_name || 'Компания не найдена';

    const getHeaderText = () => {
        const path = location.pathname;

        if (path === '/') {
            return { title: 'Вакансии', subtitle: 'Показать все вакансии' };
        }
        if (path === '/candidates') {
            return { title: 'Кандидаты', subtitle: 'Список всех кандидатов' };
        }
        if (path === '/settings/integrations') {
            return { title: 'Интеграции', subtitle: 'Домашняя страница' };
        }
        if (path === '/vacancy/create') {
            return { title: 'Новая вакансия', subtitle: 'Создайте новую вакансию' };
        }
        if (path === '/candidates/create') {
            return { title: 'Кандидаты', subtitle: 'Добавить нового кандидата' };
        }
        if (/^\/vacancy\/\d+$/.test(path)) {
            return { title: 'Детали вакансии', subtitle: 'Просмотрите информацию о вакансии' };
        }
        if (/^\/vacancy\/edit\/\d+$/.test(path)) {
            const id = path.split('/').pop(); // Извлекаем ID из пути
            return { title: `Редактирование вакансии`, subtitle: 'Редактируйте информацию о вакансии' };
        }

        return { title: 'Страница', subtitle: 'Описание страницы' };
    };


    const { title, subtitle } = getHeaderText();

    const handleLogout = async () => {
        try {
            await logout(); // Отправляем запрос на выход
            localStorage.clear(); // Очищаем localStorage
            navigate('/login'); // Перенаправляем на страницу входа
        } catch (error) {
            console.error("Ошибка при выходе из системы:", error);
        }
    };

    const onClose = async () => {
        const response = await closeHHNotify();
        setIsModalOpen(false)
    }

    return (
        <>
            <Container maxWidth="none" sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#16151C',
                            fontSize: '24px',
                            lineHeight: '28px',
                            fontWeight: 600
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            color: '#A2A1A8',
                            fontSize: '16px',
                            lineHeight: '20px',
                            fontWeight: 400
                        }}
                    >
                        {subtitle}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <IconButton
                        sx={{
                            color: '#A2A1A8',
                            backgroundColor: '#FFFFFF',
                            padding: '8px',
                            borderRadius: '8px',
                            '&:hover': { backgroundColor: '#F0F0F0' }
                        }}
                    >
                        <NotificationIcon />
                    </IconButton>

                    <Box
                        sx={{
                            width: '1px',
                            height: '24px',
                            backgroundColor: '#E0E0E0',
                        }}
                    />

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '10px' }}>
                            <img src={vacancyIcon} alt="User" style={{ width: '20px', height: '20px' }} />
                        </div>
                        {userName}
                    </Box>

                    <Select
                        IconComponent={CustomArrowIcon}
                        defaultValue={companyName}
                        sx={{
                            backgroundColor: '#FFFFFF',
                            width: '204px',
                            height: '40px',
                            borderRadius: '8px',
                            padding: '0 8px',
                            color: '#151813',
                            border: 'none',
                            boxShadow: 'none',
                            outline: 'none',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            '& .MuiSelect-select': {
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#151813',
                                display: 'flex',
                                alignItems: 'center'
                            },
                            '& fieldset': { border: 'none' }
                        }}
                    >
                        <MenuItem
                            sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#151813'
                            }}
                            value={companyName}
                        >
                            {companyName}
                        </MenuItem>
                        {data?.data?.companies && data?.data?.companies.length > 1 && (
                            <MenuItem
                                onClick={() => navigate("/select_account")}
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#151813'
                                }}
                                value="change_company"
                            >
                                Сменить компанию
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={handleLogout}
                            sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#151813'
                            }}
                            value="logout"
                        >
                            Выйти
                        </MenuItem>
                    </Select>
                </Box>
            </Container>
            <Dialog
                open={isModalOpen}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: '12px',
                        padding: '36px',
                        textAlign: 'center',
                        backgroundColor: '#FFFFFF',
                        width: '508px'
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        fontWeight: 600,
                        fontSize: '30px',
                        lineHeight: '24px',
                        color: '#151813',
                        marginBottom: '8px',
                    }}
                >
                    Привязать HeadHunter
                </DialogTitle>
                <DialogContent sx={{ width: '344px', margin: 'auto', padding: 0, height: '60px' }}>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#ADB5BD',
                        }}
                    >
                        Для того, чтобы продолжить работу, привяжите аккаунт hh.kz
                    </Typography>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '12px',
                        paddingTop: '16px',
                    }}
                >
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '18px',
                            color: '#151813',
                            borderColor: '#E2E4E7',
                            borderRadius: '8px',
                            width: '209px',
                            height: '46px',
                            padding: '8px 20px',
                            '&:hover': {
                                backgroundColor: '#F8F9F9',
                            },
                        }}
                    >
                        Отменить
                    </Button>
                    <Button
                        onClick={() => {
                            setIsModalOpen(false);
                            navigateToHH()
                        }}
                        variant="contained"
                        sx={{
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '18px',
                            color: '#FFFFFF',
                            backgroundColor: '#599D15',
                            borderRadius: '8px',
                            padding: '8px 20px',
                            width: '209px',
                            height: '46px',
                            '&:hover': {
                                backgroundColor: '#4A8B12',
                            },
                        }}
                    >
                        Привязать
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    );
};

export default Header;
