import React, { useState, useEffect } from 'react';
import { Box, Typography, List, Collapse } from '@mui/material';
import NavigationItem from './NavigationItem';
import DashboardIcon from "../../assets/icons/Icons.svg";
import DepartmentsIcon from "../../assets/icons/departmentsIcon.svg";
import CandidatesIcon from "../../assets/icons/candidatesIcon.svg";
import SettingsIcon from "../../assets/icons/settingsIcon.svg";
import IntegrationIcon from "../../assets/icons/integrationIcon.svg";
import PatternsIcon from "../../assets/icons/patternsIcon.svg";
import ReportsIcon from "../../assets/icons/reportsIcon.svg";
import StagesIcon from "../../assets/icons/stagesIcon.svg";
import TestsIcon from "../../assets/icons/testsIcon.svg";
import vacancyIcon from "../../assets/icons/vacancyIcon.svg";
import ArrowBottomIcon from "../../assets/icons/ArrowBottomIcon.svg";
import { useNavigate, useLocation } from 'react-router-dom'; // Добавлено useLocation

const NavigationBar = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Используем для отслеживания текущего пути


    // Инициализируем активный элемент из localStorage
    const [activeItem, setActiveItem] = useState(localStorage.getItem('activeNavItem') || 'Вакансии');
    const [subActiveItem, setSubActiveItem] = useState(localStorage.getItem('subActiveNavItem') || '');
    const [settingsOpen, setSettingsOpen] = useState(false);

    // Сохраняем активный элемент в localStorage при изменении
    useEffect(() => {
        const path = location.pathname;

        if (path === '/' || path.startsWith('/vacancy')) {
            setActiveItem('Вакансии');
        } else if (path === '/candidates') {
            setActiveItem('Кандидаты');
        } else if (path.startsWith('/settings')) {
            setActiveItem('Настройки');
            if (path === '/settings/integrations') {
                setSubActiveItem('Интеграции');
            }
        } else {
            setActiveItem('');
        }
    }, [location.pathname]);


    // Обработка клика на элемент навигации
    const handleItemClick = (label) => {

        setActiveItem(label[0]);
        setSubActiveItem(label[1] || '');

        if (label[0] === 'Вакансии') {
            navigate('/');
        }
        if (label[0] === 'Кандидаты') {
            navigate('/candidates');
        }

        if (label[1] === 'Интеграции') {
            navigate('/settings/integrations');
        }

        console.log(`Navigating to ${label[0]}`);
    };

    // Обработка клика на "Настройки"
    const handleSettingsClick = () => {
        setSettingsOpen(prev => !prev);
    };

    // Определяем, какие пункты должны быть отключены
    const isDisabled = (label) => label !== 'Вакансии'; // Все пункты кроме "Вакансии" будут isDisabled

    return (
        <Box sx={{ width: 292, backgroundColor: '#fff', borderRight: '1px solid #e0e0e0', height: '100vh', padding: '0 36px' }}>
            <Typography variant="h5" sx={{ padding: 2, color: '#6BA14A', fontWeight: 'bold', fontSize: '36px', lineHeight: '43px' }}>
                tapHR
            </Typography>
            <List style={{ background: '#fff' }}>
                <NavigationItem
                    icon={DashboardIcon}
                    label="Дэшборд"
                    isActive={activeItem === 'Дэшборд'}
                    onClick={() => handleItemClick(['Дэшборд'])}
                    isDisabled={isDisabled('Дэшборд')}
                    hidden
                />
                <NavigationItem
                    icon={vacancyIcon}
                    label="Вакансии"
                    isActive={activeItem === 'Вакансии'}
                    onClick={() => handleItemClick(['Вакансии'])}
                    isDisabled={false} // Всегда доступен
                />
                <NavigationItem
                    icon={CandidatesIcon}
                    label="Кандидаты"
                    isActive={activeItem === 'Кандидаты'}
                    onClick={() => handleItemClick(['Кандидаты'])}
                    isDisabled={false}
                />
                <NavigationItem
                    icon={TestsIcon}
                    label="Тесты"
                    isActive={activeItem === 'Тесты'}
                    onClick={() => handleItemClick(['Тесты'])}
                    isDisabled={isDisabled('Тесты')}
                    hidden
                />
                <NavigationItem
                    icon={ReportsIcon}
                    label="Отчеты"
                    isActive={activeItem === 'Отчеты'}
                    onClick={() => handleItemClick(['Отчеты'])}
                    isDisabled={isDisabled('Отчеты')}
                    hidden
                />

                {/* Настройки с аккордеоном */}
                <NavigationItem
                    icon={SettingsIcon}
                    label="Настройки"
                    isActive={activeItem === 'Настройки'}
                    onClick={handleSettingsClick}
                    isDisabled={false}
                    arrow={
                        <img
                            src={ArrowBottomIcon}
                            alt="arrowBottom"
                            style={{
                                transform: settingsOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                                transition: 'transform 0.3s'
                            }}
                        />
                    }
                />

                {/* Вложенные элементы под "Настройки" */}
                <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
                    <Box sx={{ paddingLeft: 2 }}>
                        <NavigationItem
                            icon={IntegrationIcon}
                            label="Пользователи"
                            isSubActive = {subActiveItem === 'Пользователи'}
                            onClick={() => handleItemClick(["Настройки", 'Пользователи'])}
                            isDisabled={isDisabled('Пользователи')}
                            hidden
                        />
                        <NavigationItem
                            icon={DepartmentsIcon}
                            label="Отделы"
                            isSubActive = {subActiveItem === 'Отделы'}
                            onClick={() => handleItemClick(["Настройки", 'Отделы'])}
                            isDisabled={isDisabled('Отделы')}
                            hidden
                        />
                        <NavigationItem
                            icon={StagesIcon}
                            label="Этапы отбора"
                            isSubActive = {subActiveItem === 'Этапы отбора'}
                            onClick={() => handleItemClick(["Настройки", 'Этапы отбора'])}
                            isDisabled={isDisabled('Этапы отбора')}
                            hidden
                        />
                        <NavigationItem
                            icon={PatternsIcon}
                            label="Шаблоны"
                            isSubActive = {subActiveItem === 'Шаблоны'}
                            onClick={() => handleItemClick(["Настройки", 'Шаблоны'])}
                            isDisabled={isDisabled('Шаблоны')}
                            hidden
                        />
                        <NavigationItem
                            icon={IntegrationIcon}
                            label="Интеграции"
                            isSubActive = {subActiveItem === 'Интеграции'}
                            onClick={() => handleItemClick(["Настройки", 'Интеграции'])}
                            isDisabled={false}
                        />
                        <NavigationItem
                            icon={IntegrationIcon}
                            label="FAQ"
                            isSubActive = {subActiveItem === 'FAQ'}
                            onClick={() => handleItemClick(["Настройки", 'FAQ'])}
                            isDisabled={isDisabled('FAQ')}
                            hidden
                        />
                    </Box>
                </Collapse>
            </List>
        </Box>
    );
};

export default NavigationBar;
