import React from 'react';
import { SvgIcon } from '@mui/material';

const PlusIcon = (props) => (
    <SvgIcon {...props}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99996 6.66663V13.3333M13.3333 9.99996H6.66663M15 1.66663H4.99996C3.15901 1.66663 1.66663 3.15901 1.66663 4.99996V15C1.66663 16.8409 3.15901 18.3333 4.99996 18.3333H15C16.8409 18.3333 18.3333 16.8409 18.3333 15V4.99996C18.3333 3.15901 16.8409 1.66663 15 1.66663Z" stroke="#599D15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);

export default PlusIcon;