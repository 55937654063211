import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Modal,
    Radio,
    FormControlLabel,
    Paper,
    Divider,
    IconButton,
    TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query'; // React Query
import { createVacancyFromExternal } from '../../services/vacancyApi'; // API функция
import closeIcon from '../../assets/icons/closeIcon.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: 'none',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};

const CreateVacancyModal = ({ open, handleClose }) => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('manual');
    const [headhunterLink, setHeadhunterLink] = useState('');

    // Мутация для создания вакансии из HeadHunter
    const { mutate: createFromExternal, isLoading } = useMutation(createVacancyFromExternal, {
        onSuccess: (data) => {
            // Передаем полученные данные на страницу создания вакансии
            navigate('/vacancy/create', { state: data });
            handleClose(); // Закрыть модальное окно
        },
        onError: (error) => {
            console.error('Ошибка создания вакансии:', error);
            alert('Не удалось создать вакансию. Проверьте введённую ссылку.');
        },
    });

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleInputChange = (event) => {
        setHeadhunterLink(event.target.value);
    };

    const handleCreate = () => {
        if (selectedOption === 'headhunter') {
            createFromExternal(headhunterLink.trim());
        } else {
            navigate('/vacancy/create'); // Переход на форму ручного создания
        }
    };

    const isCreateButtonDisabled = selectedOption === 'headhunter' && !headhunterLink.trim();

    return (
        <Modal open={open} onClose={() => {
            handleClose()
            setHeadhunterLink('')
        }}>
            <Paper sx={style}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" component="h2" sx={{ fontWeight: 600, fontSize: 20, lineHeight: '30px' }}>
                        Создать вакансию
                    </Typography>
                    <IconButton onClick={() => {
                        handleClose()
                        setHeadhunterLink('')
                    }}>
                        <img src={closeIcon} alt="Close" style={{ width: '20px', height: '20px' }} />
                    </IconButton>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Typography sx={{ mt: 2, fontWeight: 600, fontSize: '16px', lineHeight: '24px' }}>
                    Выберите способ
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={selectedOption === 'manual'}
                                onChange={handleRadioChange}
                                value="manual"
                                sx={{
                                    color: '#599D15',
                                    '&.Mui-checked': {
                                        color: '#599D15',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        width: 24,
                                        height: 24,
                                    },
                                }}
                            />
                        }
                        label={<Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>Вручную</Typography>}
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                checked={selectedOption === 'headhunter'}
                                onChange={handleRadioChange}
                                value="headhunter"
                                sx={{
                                    color: '#599D15',
                                    '&.Mui-checked': {
                                        color: '#599D15',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        width: 24,
                                        height: 24,
                                    },
                                }}
                            />
                        }
                        label={<Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>Из HeadHunter</Typography>}
                    />
                </Box>
                {selectedOption === 'headhunter' && (
                    <TextField
                        value={headhunterLink}
                        onChange={handleInputChange}
                        placeholder="Вставить ссылку"
                        fullWidth
                        sx={{
                            marginTop: 2,
                            backgroundColor: "#F8F9F9",
                            borderRadius: "8px",
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                height: "52px",
                                "& fieldset": {
                                    borderColor: "transparent",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#BDBDBD",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#599D15",
                                },
                            },
                        }}
                    />
                )}
                <Box sx={{ display: 'flex', mt: 4 }}>
                    <Button
                        onClick={() => {
                            handleClose()
                            setHeadhunterLink('')
                        }}
                        variant="outlined"
                        sx={{
                            marginRight: 2,
                            borderColor: '#599D15',
                            color: '#599D15',
                            flex: 1,
                            height: '44px',
                            borderRadius: '8px',
                            '&:hover': {
                                backgroundColor: 'rgba(89, 157, 21, 0.1)',
                            },
                        }}
                    >
                        Отменить
                    </Button>
                    <Button
                        onClick={handleCreate}
                        variant="contained"
                        disabled={isCreateButtonDisabled || isLoading}
                        sx={{
                            backgroundColor: isCreateButtonDisabled || isLoading ? '#E0E0E0' : '#599D15',
                            color: isCreateButtonDisabled || isLoading ? '#A2A2A2' : 'white',
                            flex: 1,
                            height: '44px',
                            borderRadius: '8px',
                            '&:hover': {
                                backgroundColor: isCreateButtonDisabled || isLoading ? '#E0E0E0' : '#4A8512',
                            },
                        }}
                    >
                        {isLoading ? 'Создание...' : 'Создать'}
                    </Button>
                </Box>
            </Paper>
        </Modal>
    );
};

export default CreateVacancyModal;
