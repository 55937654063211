import { Box } from '@mui/material';

const defaultSvgSx = {
    width: '24px',
    height: '24px',
    color: 'black',
    backgroundColor: 'none',
};

const getSx = (sx, svgSx) => {
    const newSx = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
    }

    if (svgSx.hoverColor) {
        newSx['&:hover path'] = {
            fill: svgSx.hoverColor,
        }
    }

    if (svgSx.pressedColor) {
        newSx['&:active path'] = {
            fill: svgSx.pressedColor,
        }
    }
    return newSx;
}

/**
 * 
 * @param {Object} sx - styles for the icon container
 * @param {Object} svgSx - styles for the svg icon 
 * @returns {JSX.Element} - box with svg inside
 */
export const CloseIcon = ({ onClick = () => { }, sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box
            onClick={onClick}
            sx={getSx(sx, mergedSvgSx)}
        >
            <svg
                width={mergedSvgSx.width}
                height={mergedSvgSx.height}
                viewBox="0 0 24 24"
                fill={mergedSvgSx.backgroundColor}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                    fill={mergedSvgSx.color}
                />
            </svg>
        </Box>
    )
}

/**
 * 
 * @param {Object} sx - styles for the icon container
 * @param {Object} svgSx - styles for the svg icon 
 * @returns {JSX.Element} - box with svg inside
 */
export const ProfileIcon = ({ onClick = () => { }, sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box
            onClick={onClick}
            sx={getSx(sx, mergedSvgSx)}
        >
            <svg width={mergedSvgSx.width} height={mergedSvgSx.height} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.8889 21V18.7778C18.8889 17.599 18.4206 16.4686 17.5871 15.6351C16.7536 14.8016 15.6232 14.3333 14.4444 14.3333H5.55553C4.37679 14.3333 3.24633 14.8016 2.41283 15.6351C1.57934 16.4686 1.11108 17.599 1.11108 18.7778V21M14.4444 5.44444C14.4444 7.89904 12.4546 9.88889 9.99997 9.88889C7.54537 9.88889 5.55553 7.89904 5.55553 5.44444C5.55553 2.98985 7.54537 1 9.99997 1C12.4546 1 14.4444 2.98985 14.4444 5.44444Z" stroke={mergedSvgSx.color} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </Box>
    )
}


export const WarningIcon = ({ onClick = () => { }, sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box sx={getSx(sx, mergedSvgSx)} onClick={onClick}>
            <svg width={mergedSvgSx.width} height={mergedSvgSx.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke={mergedSvgSx.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        </Box>
    );
}

/**
 * 
 * @param {Object} sx - styles for the icon container
 * @param {Object} svgSx - styles for the svg icon 
 * @returns {JSX.Element} - box with svg inside
 */
export const ArrowUpIcon = ({ onClick = () => { }, sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box
            onClick={onClick}
            sx={getSx(sx, mergedSvgSx)}
        >
            <svg width={mergedSvgSx.width} height={mergedSvgSx.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 20L12 4M12 4L18 10M12 4L6 10" stroke={mergedSvgSx.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    )
}

/**
 * 
 * @param {Object} sx - styles for the icon container
 * @param {Object} svgSx - styles for the svg icon 
 * @returns {JSX.Element} - box with svg inside
 */
export const ArrowDownIcon = ({ onClick = () => { }, sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box
            onClick={onClick}
            sx={getSx(sx, mergedSvgSx)}
        >
            <svg width={mergedSvgSx.width} height={mergedSvgSx.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 3.25C12.4142 3.25 12.75 3.58579 12.75 4L12.75 18.1893L17.4697 13.4697C17.7626 13.1768 18.2374 13.1768 18.5303 13.4697C18.8232 13.7626 18.8232 14.2374 18.5303 14.5303L12.5303 20.5303C12.3897 20.671 12.1989 20.75 12 20.75C11.8011 20.75 11.6103 20.671 11.4697 20.5303L5.46967 14.5303C5.17678 14.2374 5.17678 13.7626 5.46967 13.4697C5.76256 13.1768 6.23744 13.1768 6.53033 13.4697L11.25 18.1893L11.25 4C11.25 3.58579 11.5858 3.25 12 3.25Z" fill={mergedSvgSx.color} />
            </svg>
        </Box>
    )
}

/**
 * 
 * @param {Object} sx - styles for the icon container
 * @param {Object} svgSx - styles for the svg icon 
 * @returns {JSX.Element} - box with svg inside
 */
export const MenuIcon = ({ onClick = () => { }, sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box
            onClick={onClick}
            sx={getSx(sx, mergedSvgSx)}
        >
            <svg fill={mergedSvgSx.color} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                width={mergedSvgSx.width} height={mergedSvgSx.height} viewBox="0 0 24.75 24.75"
            >
                <g>
                    <path d="M0,3.875c0-1.104,0.896-2,2-2h20.75c1.104,0,2,0.896,2,2s-0.896,2-2,2H2C0.896,5.875,0,4.979,0,3.875z M22.75,10.375H2
                        c-1.104,0-2,0.896-2,2c0,1.104,0.896,2,2,2h20.75c1.104,0,2-0.896,2-2C24.75,11.271,23.855,10.375,22.75,10.375z M22.75,18.875H2
                        c-1.104,0-2,0.896-2,2s0.896,2,2,2h20.75c1.104,0,2-0.896,2-2S23.855,18.875,22.75,18.875z"/>
                </g>
            </svg>
        </Box>
    )
}

/**
 * 
 * @param {Object} sx - styles for the icon container
 * @param {Object} svgSx - styles for the svg icon 
 * @returns {JSX.Element} - box with svg inside
 */
export const LockIcon = ({ onClick = () => { }, sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box
            onClick={onClick}
            sx={getSx(sx, mergedSvgSx)}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill={mergedSvgSx.color} viewBox="0 0 48 48" width={mergedSvgSx.width} height={mergedSvgSx.height}>
                <path d="M 24 4 C 19.599415 4 16 7.599415 16 12 L 16 16 L 12.5 16 C 10.032499 16 8 18.032499 8 20.5 L 8 39.5 C 8 41.967501 10.032499 44 12.5 44 L 35.5 44 C 37.967501 44 40 41.967501 40 39.5 L 40 20.5 C 40 18.032499 37.967501 16 35.5 16 L 32 16 L 32 12 C 32 7.599415 28.400585 4 24 4 z M 24 7 C 26.779415 7 29 9.220585 29 12 L 29 16 L 19 16 L 19 12 C 19 9.220585 21.220585 7 24 7 z M 12.5 19 L 35.5 19 C 36.346499 19 37 19.653501 37 20.5 L 37 39.5 C 37 40.346499 36.346499 41 35.5 41 L 12.5 41 C 11.653501 41 11 40.346499 11 39.5 L 11 20.5 C 11 19.653501 11.653501 19 12.5 19 z M 24 27 A 3 3 0 0 0 24 33 A 3 3 0 0 0 24 27 z" />
            </svg>

        </Box>
    )
}

/**
 * 
 * @param {Object} sx - styles for the icon container
 * @param {Object} svgSx - styles for the svg icon 
 * @returns {JSX.Element} - box with svg inside
 */
export const PlusIcon = ({ onClick = () => { }, sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box
            onClick={onClick}
            sx={getSx(sx, mergedSvgSx)}
        >
            <svg
                width={mergedSvgSx.width}
                height={mergedSvgSx.height}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M4 12H20M12 4V20" stroke={mergedSvgSx.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    )
}

/**
 * 
 * @param {Object} sx - styles for the icon container
 * @param {Object} svgSx - styles for the svg icon 
 * @returns {JSX.Element} - box with svg inside
 */
export const RefreshIcon = ({ onClick = () => { }, sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box
            onClick={onClick}
            sx={getSx(sx, mergedSvgSx)}
        >
            <svg width={mergedSvgSx.width} height={mergedSvgSx.height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1845_7142)">
                <path d="M10.3393 4.5L10.9355 6.00161C10.3705 5.68321 9.70816 5.5 9 5.5C6.92893 5.5 5.25 7.067 5.25 9C5.25 9.31115 5.2935 9.61282 5.37514 9.9M7.66071 13.5L7.06449 11.9984C7.62951 12.3168 8.29183 12.5 9 12.5C11.0711 12.5 12.75 10.933 12.75 9C12.75 8.68885 12.7065 8.38718 12.6249 8.1" stroke={mergedSvgSx.color} strokeWidth="1.35" strokeLinecap="round" strokeLinejoin="round"/>
                <circle cx="9" cy="9" r="7.5" stroke={mergedSvgSx.color} strokeWidth="1.35"/>
                </g>
                <defs>
                <clipPath id="clip0_1845_7142">
                    <rect width="18" height="18" fill={mergedSvgSx.color}/>
                </clipPath>
                </defs>
            </svg>
        </Box>
    )
}


/**
 * 
 * @param {Object} sx - styles for the icon container
 * @param {Object} svgSx - styles for the svg icon 
 * @returns {JSX.Element} - box with svg inside
 */
export const VacancyIcon = ({ sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box sx={getSx(sx, mergedSvgSx)}>
            <svg
                width={mergedSvgSx.width}
                height={mergedSvgSx.height}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8 6V5C8 3.34315 9.34315 2 11 2H13C14.6569 2 16 3.34315 16 5V6M2 10.3475C2 10.3475 5.11804 12.4244 9.97767 12.9109M22 10.3475C22 10.3475 18.882 12.4244 14.0223 12.9109M6 22H18C20.2091 22 22 20.2091 22 18V10C22 7.79086 20.2091 6 18 6H6C3.79086 6 2 7.79086 2 10V18C2 20.2091 3.79086 22 6 22Z"
                    fill="#fff"
                    stroke={mergedSvgSx.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M14 12.16V13.16C14 13.17 14 13.17 14 13.18C14 14.27 13.99 15.16 12 15.16C10.02 15.16 10 14.28 10 13.19V12.16C10 11.16 10 11.16 11 11.16H13C14 11.16 14 11.16 14 12.16Z"
                    fill="none"
                    stroke={mergedSvgSx.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Box>
    );
}

/**
 * 
 * @param {Object} sx - styles for the icon container
 * @param {Object} svgSx - styles for the svg icon 
 * @returns {JSX.Element} - color and background color of this svg can not be cantrolled
 */
export const WhatsIcon = ({ sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box sx={getSx(sx, mergedSvgSx)}>
            <svg width={mergedSvgSx.width} height={mergedSvgSx.height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16 31C23.732 31 30 24.732 30 17C30 9.26801 23.732 3 16 3C8.26801 3 2 9.26801 2 17C2 19.5109 2.661 21.8674 3.81847 23.905L2 31L9.31486 29.3038C11.3014 30.3854 13.5789 31 16 31ZM16 28.8462C22.5425 28.8462 27.8462 23.5425 27.8462 17C27.8462 10.4576 22.5425 5.15385 16 5.15385C9.45755 5.15385 4.15385 10.4576 4.15385 17C4.15385 19.5261 4.9445 21.8675 6.29184 23.7902L5.23077 27.7692L9.27993 26.7569C11.1894 28.0746 13.5046 28.8462 16 28.8462Z" fill="#BFC8D0" />
                <path d="M28 16C28 22.6274 22.6274 28 16 28C13.4722 28 11.1269 27.2184 9.19266 25.8837L5.09091 26.9091L6.16576 22.8784C4.80092 20.9307 4 18.5589 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z" fill="url(#paint0_linear_87_7264)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 18.5109 2.661 20.8674 3.81847 22.905L2 30L9.31486 28.3038C11.3014 29.3854 13.5789 30 16 30ZM16 27.8462C22.5425 27.8462 27.8462 22.5425 27.8462 16C27.8462 9.45755 22.5425 4.15385 16 4.15385C9.45755 4.15385 4.15385 9.45755 4.15385 16C4.15385 18.5261 4.9445 20.8675 6.29184 22.7902L5.23077 26.7692L9.27993 25.7569C11.1894 27.0746 13.5046 27.8462 16 27.8462Z" fill="white" />
                <path d="M12.5 9.49989C12.1672 8.83131 11.6565 8.8905 11.1407 8.8905C10.2188 8.8905 8.78125 9.99478 8.78125 12.05C8.78125 13.7343 9.52345 15.578 12.0244 18.3361C14.438 20.9979 17.6094 22.3748 20.2422 22.3279C22.875 22.2811 23.4167 20.0154 23.4167 19.2503C23.4167 18.9112 23.2062 18.742 23.0613 18.696C22.1641 18.2654 20.5093 17.4631 20.1328 17.3124C19.7563 17.1617 19.5597 17.3656 19.4375 17.4765C19.0961 17.8018 18.4193 18.7608 18.1875 18.9765C17.9558 19.1922 17.6103 19.083 17.4665 19.0015C16.9374 18.7892 15.5029 18.1511 14.3595 17.0426C12.9453 15.6718 12.8623 15.2001 12.5959 14.7803C12.3828 14.4444 12.5392 14.2384 12.6172 14.1483C12.9219 13.7968 13.3426 13.254 13.5313 12.9843C13.7199 12.7145 13.5702 12.305 13.4803 12.05C13.0938 10.953 12.7663 10.0347 12.5 9.49989Z" fill="white" />
                <defs>
                    <linearGradient id="paint0_linear_87_7264" x1="26.5" y1="7" x2="4" y2="28" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5BD066" />
                        <stop offset="1" stopColor="#27B43E" />
                    </linearGradient>
                </defs>
            </svg>
        </Box>
    );
}

/**
 * 
 * @param {Object} sx - styles for the icon container
 * @param {Object} svgSx - styles for the svg icon 
 * @returns {JSX.Element} - color and background color of this svg can not be cantrolled
 */
export const TelegramIcon = ({ sx = {}, svgSx = {} }) => {
    const mergedSvgSx = { ...defaultSvgSx, ...svgSx };
    return (
        <Box sx={getSx(sx, mergedSvgSx)}>
            <svg width={mergedSvgSx.width} height={mergedSvgSx.height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="14" fill="url(#paint0_linear_87_7225)" />
                <path d="M22.9866 10.2088C23.1112 9.40332 22.3454 8.76755 21.6292 9.082L7.36482 15.3448C6.85123 15.5703 6.8888 16.3483 7.42147 16.5179L10.3631 17.4547C10.9246 17.6335 11.5325 17.541 12.0228 17.2023L18.655 12.6203C18.855 12.4821 19.073 12.7665 18.9021 12.9426L14.1281 17.8646C13.665 18.3421 13.7569 19.1512 14.314 19.5005L19.659 22.8523C20.2585 23.2282 21.0297 22.8506 21.1418 22.1261L22.9866 10.2088Z" fill="white" />
                <defs>
                    <linearGradient id="paint0_linear_87_7225" x1="16" y1="2" x2="16" y2="30" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#37BBFE" />
                        <stop offset="1" stopColor="#007DBB" />
                    </linearGradient>
                </defs>
            </svg>
        </Box>
    );
}

