import React, { useState, memo, useCallback } from 'react';
import {
    Box,
    Typography,
    Divider,
    TextField,
    Checkbox,
    FormControlLabel,
    Button,
    Avatar
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getTimeStampFomatted } from '../../utils/formatedDate';
import { createComment, updateComment, deleteComment } from '../../services/commentsApi';

const ShowCommentUi = ({candidateId, isChangeble, comment, handleActivity}) => {
    const [isEditable, setIsEditable] = useState(false);
    const [text, setText] = useState(comment.text);

    const updateMutation = useMutation(updateComment, {
        onSuccess: (data) => {
            handleActivity(data);
            setIsEditable(false);
        },
        onError: (error) => {
            console.error("Error updating comment:", error.message);
        },
    });

    const deleteMutation = useMutation(deleteComment, {
        onSuccess: (data) => {
            handleActivity(data);
        },
        onError: (error) => {
            console.error("Error deleting comment:", error.message);
        },
    });

    const handleDelete = () => {
        deleteMutation.mutate({
            candidateId: candidateId,
            commentId: comment.id,
        });
    };

    const handleUpdate = () => {
        if (!isEditable) {
            setIsEditable(true);
            return;
        }

        if (!text || comment.text === text) {
            setIsEditable(false);
            return;
        }

        updateMutation.mutate({
            text: text,
            candidateId: candidateId,
            commentId: comment.id,
        });
    }

    const isLoading = deleteMutation.isLoading || updateMutation.isLoading;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "16px 0",
                backgroundColor: "rgba(255, 255, 255, 1)",
                position: "relative",
                pointerEvents: isLoading ? "none" : "auto", 
            }}
        >

            {/* Dimmed overlay */}
            {isLoading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "transparent", // Semi-transparent overlay
                        zIndex: 1, // To cover the content
                    }}
                />
            )}

            {/* Avatar */}
            <Avatar
                src={comment.manager.avatar || null}
                alt={`${comment.manager.name}`}
                sx={{
                    width: "40px",
                    height: "40px",
                    bgcolor: "rgba(89, 157, 21, 1)",
                    color: "#fff",
                    fontWeight: "bold",
                }}
            />

            {/* Content */}
            <Box sx={{ marginLeft: "16px", flex: 1 }}>
                {/* Header */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "8px",
                    }}
                >
                    {/* Name */}
                    <Typography
                        sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "20px", color: "rgba(31, 41, 55, 1)" }}
                    >
                        {comment.manager.name}
                    </Typography>
                    {/* Time */}
                    <Typography
                        sx={{
                            fontSize: "14px",
                            lineHeight: "18px",
                            color: "rgba(107, 114, 128, 1)",
                        }}
                    >
                        {getTimeStampFomatted(comment.created_at)}
                    </Typography>
                </Box>

                {/* Comment Text */}
                <TextField
                    placeholder="Добавить комментарий"
                    multiline={isEditable}
                    value={text} // Controlled value
                    onChange={(e) => {
                        if (isEditable) {
                            setText(e.target.value); // Allow typing only if editable
                        }
                    }}
                    sx={{
                        '& .MuiInputBase-root': {
                            alignItems: 'flex-start',
                            padding: 0,
                            "& fieldset": {
                                border: isEditable ? "1px solid rgba(210, 214, 218, 1)" : "none", // Remove the fieldset border
                            },
                        },
                        '& .MuiInputBase-input': {
                            resize: 'vertical',
                            minHeight: '20px',
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: "rgba(31, 41, 55, 1)",
                            padding: 0,
                        },
                    }}
                    fullWidth
                    slotProps={{
                        input: {
                          readOnly: !isEditable,
                        },
                      }}
                />

                {/* Actions */}
                <Box
                    sx={{
                        marginTop: "8px",
                        display: "flex",
                        fontSize: "12px",
                        color: "rgba(46, 64, 82, 1)",
                    }}
                >
                    {/* <Typography
                        component="button"
                        underline="none"
                        sx={{ color: "rgba(46, 64, 82, 1)", fontWeight: 500 }}
                    >
                        Ответить
                    </Typography> */}
                    {isChangeble && 
                    <>
                        <Typography
                            component="button"
                            underline="none"
                            sx={{ color: "rgba(46, 64, 82, 1)", fontWeight: 500, fontSize: "12px", lineHeight: "20px", border: "none", cursor: "pointer", backgroundColor: "transparent", "&:hover" : {color: "rgba(89, 157, 21, 1)"} }}
                            onClick={handleUpdate}
                        >
                            {isEditable ? "Сохранить" : "Изменить"}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "16px",
                                width: "16px",
                                textAlign: "center",
                                verticalAlign: "middle", // Align to middle of the text
                                position: "relative",
                                top: "3px", // Adjust vertical position
                                color: "rgba(46, 64, 82, 1)",
                            }}
                        >•</Typography>
                        <Typography
                            component="button"
                            underline="none"
                            sx={{ color: "rgba(46, 64, 82, 1)", fontWeight: 500, fontSize: "12px", lineHeight: "20px", border: "none", cursor: "pointer", backgroundColor: "transparent", "&:hover" : {color: "rgba(89, 157, 21, 1)"} }}
                            onClick={handleDelete}
                        >
                            Удалить
                        </Typography>
                    </>}
                </Box>
            </Box>
        </Box>
    )
}

const NewCommentUi = memo(({candidateId, handleActivity}) => {
    const [focused, setFocused] = useState(false);
    const [text, setText] = useState('');
    const [isChecked, setIsChecked] = useState(true);

    const handleClear = () => {
        setFocused(false); // Reset the focused state
        setText(''); // Reset the value to an empty string
        setIsChecked(true); // Reset the checkbox state
    };
    
    const createMutation = useMutation(createComment, {
        onSuccess: (data) => {
            handleClear();
            handleActivity(data);
        },
        onError: (error) => {
            console.error("Error saving comment:", error.message);
        },
    });

    console.log("Main comment being rendered")

    const handleSave = () => {
        if (!text) {
            return;
        }

        // Call the API to create a new comment
        createMutation.mutate({
            text: text,
            is_visible: isChecked,
            candidateId: candidateId,
        });
    };

    return (
        <>
            {/* Title */}
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: '20px',
                    lineHeight: '24px',
                    display: 'block',
                }}
            >
                Комментарии
            </Typography>

            {/* Divider */}
            {<Divider sx={{ backgroundColor: 'rgba(235, 238, 239, 1)' }} />}

            {/* Text Area */}
            <TextField
                placeholder="Добавить комментарий"
                multiline
                value={text} // Controlled value
                onChange={(e) => setText(e.target.value)} // Update state on change
                onFocus={() => setFocused(true)}
                sx={{
                    '& .MuiInputBase-root': {
                        alignItems: 'flex-start',
                        transition: 'min-height 0.3s',
                        padding: '7px 13px',
                        minWidth: 392,
                    },
                    '& .MuiInputBase-input': {
                        resize: 'vertical',
                        minHeight: focused ? '93px' : '20px',
                        fontSize: '16px',
                        lineHeight: '20px',
                        padding: 0,
                    },
                }}
                fullWidth
            />

            {/* Checkbox */}
            {focused && (
                <FormControlLabel
                    sx = {{margin: 0, display: 'flex', alignItems: 'center', gap: '8px'}}
                    control={
                        <Checkbox
                            checked = {isChecked}
                            onChange = {() => setIsChecked(prev => !prev)}
                            sx={{
                                width: '16px',
                                height: '16px',
                                borderRadius: '4px',
                                color: 'rgba(89, 157, 21, 1)',
                                '&.Mui-checked': {
                                    color: 'rgba(244, 247, 242, 1)',
                                },
                                '& .MuiSvgIcon-root': {
                                    borderRadius: '4px',
                                    backgroundColor: isChecked ? 'rgba(89, 157, 21, 1)' : 'rgba(244, 247, 242, 1)',
                                },
                                
                            }}
                        />
                    }
                    label={
                        <Typography
                            sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '18px' }}
                        >
                            Видно всем
                        </Typography>
                    }
                />
            )}

            {/* Buttons */}
            {focused && (
                <Box sx={{ display: 'flex', gap: '18px' }}>
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        sx={{
                            width: '226px',
                            height: '46px',
                            borderRadius: '8px',
                            padding: '12px 20px',
                            backgroundColor: 'rgba(89, 157, 21, 1)',
                            color: '#fff',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: 'rgba(80, 140, 19, 1)',
                            },
                            '&:active': {
                                backgroundColor: 'rgba(69, 122, 16, 1)',
                            },
                        }}
                    >
                        Сохранить
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={handleClear}
                        sx={{
                            width: '226px',
                            height: '46px',
                            borderRadius: '8px',
                            padding: '12px 20px',
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            color: 'rgba(46, 64, 82, 1)',
                            border: '1px solid rgba(46, 64, 82, 1)',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: 'rgba(242, 243, 243, 1)',
                            },
                            '&:active': {
                                backgroundColor: 'rgba(226, 228, 231, 1)',
                            },
                        }}
                    >
                        Отменить
                    </Button>
                </Box>
            )}
        </>
    );
})

const CommentListUi = memo(({candidateId, comments, handleActivity}) => {
    const managerId = localStorage.getItem("manager_id") || null;
    console.log("Comments being rendered")

    return (
        <>
            {comments && comments.map((comment) => {
                return <ShowCommentUi key = {comment.id} candidateId= {candidateId} isChangeble={managerId !== null && +managerId === +comment.manager.id} comment={comment} handleActivity={handleActivity}/>
            })}
        </>
    );
})

const CommentSectionUi = memo(({candidateId, comments, queryKey}) => {
    const queryClient = useQueryClient();

    const handleActivity = function (comments) {
        if (!comments) {
            return;
        }

        queryClient.setQueryData(queryKey, (oldData) => {
            return {
                ...oldData,
                comments: comments,  // Replacing the comments field
            };
        });

    }

    return (
        <Box
            sx={{
                backgroundColor: 'rgba(255, 255, 255, 1)',
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                borderRadius: '8px',
                transition: 'all 0.3s',
            }}
        >
            <NewCommentUi candidateId={candidateId} handleActivity={handleActivity}/>
            <CommentListUi candidateId={candidateId} comments={comments} handleActivity={handleActivity}/>
        </Box>
    );
})

export default CommentSectionUi;
