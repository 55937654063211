import axiosClassic from "../api/interceptors";

// Создание нового кандидата
export const createCandidate = async (candidateData) => {
    const companyId = localStorage.getItem('company_id'); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(
            `/api/company/${companyId}/candidates`,
            candidateData, // Передаём FormData
            {
                headers: {
                    "Content-Type": "multipart/form-data", // Указываем тип контента для FormData
                },
            }
        );
        return response.data; // Возвращаем ответ от API
    } catch (error) {
        console.error("Ошибка при создании кандидата:", error);
        throw error; // Пробрасываем ошибку дальше для обработки
    }
};


// Запрос на получение списка кандидатов
export const fetchCandidates = async (page = 1, searchQuery = "") => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    const params = {
        page,
        ...(searchQuery ? { name: searchQuery } : {}), // Добавляем фильтр по имени, если задано
    };

    const response = await axiosClassic.get(`/api/company/${companyId}/candidates`, { params });
    return {
        candidates: response.data.data,
        pagination: response.data.meta,
    };
};

// Получение данных конкретного кандидата
export const fetchCandidateById = async (candidateId) => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.get(`/api/company/${companyId}/candidates/${candidateId}`);
    return response.data.data; // Предполагается, что данные кандидата находятся в `data.data`
};

// Смена этапа подбора кандидата
export const updateCandidateStage = async (negotiationId, stageId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");
    if (!negotiationId || !stageId) throw new Error("Не переданы необходимые параметры");

    try {
        const response = await axiosClassic.put(
            `/api/company/${companyId}/negotiations/${negotiationId}`,
            { stage_id: stageId }
        );
        return response.data;
    } catch (error) {
        console.error("Ошибка при смене этапа подбора:", error);
        throw error; // Пробрасываем ошибку для обработки
    }
};

export const discardCandidate = async ({ companyId, candidateId, reason }) => {
    const response = await axiosClassic.put(
        `/api/company/${companyId}/candidates/${candidateId}/discard`,
        { reason }
    );
    return response.data;
};

