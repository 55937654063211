import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchVacancyByIdOnEdit, updateVacancy } from "../../services/vacancyApi";
import FirstStep from "../../components/vacancy/FirstStep";
import { Box, CircularProgress, Typography, Container, Paper, Button } from "@mui/material";

const EditVacancyPage = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Получаем ID вакансии из URL
    const queryClient = useQueryClient();

    // Локальные состояния
    const [name, setName] = useState("");
    const [salaryFrom, setSalaryFrom] = useState("");
    const [salaryTo, setSalaryTo] = useState("");
    const [currency, setCurrency] = useState("KZT");
    const [experience, setExperience] = useState("");
    const [employmentType, setEmploymentType] = useState("");
    const [workSchedule, setWorkSchedule] = useState("");
    const [taxType, setTaxType] = useState(true);
    const [text, setText] = useState("");
    const [city, setCity] = useState(null);
    const [department, setDepartment] = useState(null);
    const [acceptTemporary, setAcceptTemporary] = useState(false);

    // Запрос данных вакансии
    const { data: vacancy, isLoading, error } = useQuery(
        ["vacancy", id],
        () => fetchVacancyByIdOnEdit(id),
        {
            onSuccess: (data) => {
                // Предзаполнение состояний при успешной загрузке данных
                setName(data.name || "");
                setSalaryFrom(data.salary_from || "");
                setSalaryTo(data.salary_to || "");
                setCurrency(data.salary_currency || "KZT");
                setExperience(data.experience || "");
                setEmploymentType(data.employment || "");
                setWorkSchedule(data.schedule || "");
                setTaxType(data.salary_gross || true);
                setText(data.description || "");
                setCity(data.city_name ? { name: data.city_name, id: data.hh_area_id } : null);
                setDepartment(data.department_name ? { name: data.department_name, id: data.department_id } : null);
                setAcceptTemporary(data.accept_temporary || false);
            },
        }
    );

    // Мутация для обновления вакансии
    const mutation = useMutation(
        (vacancyData) => updateVacancy(id, vacancyData),
        {
            onSuccess: () => {
                // Обновляем кэш вакансий
                queryClient.invalidateQueries(["vacancies"]);
                alert("Вакансия успешно обновлена!");
                navigate(`/vacancy/${id}`);
            },
            onError: (error) => {
                alert("Ошибка при обновлении вакансии:", error.message || error);
            },
        }
    );

    const handleUpdate = () => {
        // Подготовка данных для отправки
        const body = {
            name,
            description: text,
            salary_from: salaryFrom || null,
            salary_to: salaryTo || null,
            salary_gross: Boolean(taxType),
            salary_currency: currency,
            city_name: city?.name,
            hh_area_id: city?.id,
            recruiter_id: localStorage.getItem("manager_id"),
            department_id: department?.id,
            experience,
            schedule: workSchedule,
            employment: employmentType,
            accept_temporary: acceptTemporary,
        };

        mutation.mutate(body); // Отправляем запрос на обновление
    };

    const handleBack = () => {
        navigate(-1); // Возвращаемся на предыдущую страницу
    };

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <Typography color="error">Ошибка при загрузке данных вакансии</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{
            mt: 4, mb: 8, maxWidth: {
                xs: '100%', // Для маленьких экранов
                sm: '100%', // Для мобильных экранов
                md: '960px', // Для средних экранов
                lg: '1200px', // Для больших экранов
                xl: 'calc(100% - 100px)', // Для очень больших экранов
            }
        }}>
            <Paper
                elevation={0}
                sx={{
                    p: 4,
                    borderRadius: 2,
                    backgroundColor: "#FFFFFF",
                    border: "none",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "calc(90vh - 25px)",
                }}
            >
                <Box sx={{ p: 3 }}>
                    <FirstStep
                        name={name}
                        setName={setName}
                        salaryFrom={salaryFrom}
                        setSalaryFrom={setSalaryFrom}
                        salaryTo={salaryTo}
                        setSalaryTo={setSalaryTo}
                        currency={currency}
                        setCurrency={setCurrency}
                        experience={experience}
                        setExperience={setExperience}
                        employmentType={employmentType}
                        setEmploymentType={setEmploymentType}
                        workSchedule={workSchedule}
                        setWorkSchedule={setWorkSchedule}
                        taxType={taxType}
                        setTaxType={setTaxType}
                        text={text}
                        setText={setText}
                        city={city}
                        setCity={setCity}
                        department={department}
                        setDepartment={setDepartment}
                        accept_temporary={acceptTemporary}
                        set_accept_temporary={setAcceptTemporary}
                    />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                    <Button
                        variant="outlined"
                        sx={{
                            marginRight: 2,
                            height: "56px",
                            boxShadow: "none",
                            borderRadius: "8px",
                            width: "202px",
                            color: "#599D15",
                            borderColor: "#599D15",
                            fontSize: "16px",
                            fontWeight: "400",
                        }}
                        onClick={handleBack}
                    >
                        Назад
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: !name || !city || !department || !salaryFrom || !salaryTo ? "#E0E0E0" : "#599D15",
                            color: !name || !city || !department || !salaryFrom || !salaryTo ? "#A0A0A0" : "white",
                            height: "56px",
                            boxShadow: "none",
                            borderRadius: "8px",
                            width: "202px",
                            fontSize: "16px",
                            fontWeight: "400",
                        }}
                        onClick={handleUpdate}
                        disabled={!name || !city || !department || !salaryFrom || !salaryTo} // Проверка на заполненность
                    >
                        Обновить
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default EditVacancyPage;
