import React, { useState } from "react";
import {
    Box,
    Button,
    Typography,
    Paper,
    Container,
    IconButton,
    Divider,
    Snackbar,
    Alert
} from "@mui/material";
import LabeledInput from "../../components/main/LabeledInput";
import CustomAutocomplete from "../../components/main/CustomAutocomplete";
import { useInfiniteQuery, useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchCities, fetchCurrencies } from "../../services/dictionariesApi";
import { fetchVacancies } from "../../services/vacancyApi";
import { createCandidate } from "../../services/candidatesApi";
import { useNavigate } from "react-router-dom";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";




const CreateCandidatesPage = () => {
    const [photo, setPhoto] = useState(null); // Состояние для фотографии
    const [photoPreview, setPhotoPreview] = useState(null); // Состояние для превью фото
    const [rowsPerPage, setRowsPerPage] = useState(10); // Количество строк на странице
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [patronymic, setPatronymic] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [desiredPosition, setDesiredPosition] = useState("");
    const [desiredSalary, setDesiredSalary] = useState("");
    const [currency, setCurrency] = useState("KZT");
    const [vacancy, setVacancy] = useState([]); // Множественный выбор требует массива
    const [citySearchQuery, setCitySearchQuery] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // error (красный) или success (зелёный)


    const queryClient = useQueryClient();

    const [attachedFile, setAttachedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (!file) return;

        // Ограничения на размер и форматы
        const maxFileSize = 10 * 1024 * 1024; // 10 MB
        const allowedFormats = [
            "application/pdf",
            "application/msword", // .doc
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
            "image/jpeg",
            "image/png",
        ];

        // Проверка размера
        if (file.size > maxFileSize) {
            showSnackbar("Размер файла не должен превышать 10MB.");
            event.target.value = ""; // Сбрасываем выбор
            return;
        }

        // Проверка формата
        if (!allowedFormats.includes(file.type)) {
            showSnackbar("Допустимые форматы: PDF, DOC, DOCX, JPG, PNG.");
            event.target.value = ""; // Сбрасываем выбор
            return;
        }

        // Если всё ок, сохраняем файл
        setAttachedFile(file);
        console.log("Файл прикреплен:", file.name);
    };

    const handleRemoveFile = () => {
        setAttachedFile(null); // Удаляем файл
    };

    // Запрос на города с пагинацией
    const {
        data: cityPages,
        fetchNextPage: fetchNextCityPage,
        hasNextPage: hasNextCityPage,
        isFetchingNextPage: isFetchingNextCityPage,
        isLoading: isCitiesLoading,
    } = useInfiniteQuery(
        ["cities", citySearchQuery],
        ({ pageParam = 1 }) => fetchCities(citySearchQuery, pageParam, 10),
        {
            getNextPageParam: (lastPage) => {
                const currentPage = parseInt(lastPage.current_page, 10);
                const totalPages = Math.ceil(lastPage.total / lastPage.per_page);
                return currentPage < totalPages ? currentPage + 1 : undefined;
            },
        }
    );

    const cityOptions = cityPages?.pages.flatMap((page) => page.data) || [];

    // Запрос на вакансии
    const {
        data: vacancyOptions = [],
        fetchNextPage: fetchNextVacanciesPage,
        hasNextPage: hasNextVacanciesPage,
        isFetchingNextPage: isFetchingNextVacanciesPage,
        isLoading: isVacanciesLoading
    } = useQuery(['vacancies', currentPage, rowsPerPage], () =>
        fetchVacancies(currentPage, rowsPerPage)
    );


    // Запрос на валюты
    const { data: currencyOptions = [], isLoading: isCurrenciesLoading } = useQuery(
        ["currencies"],
        fetchCurrencies
    );

    // Мутация для создания кандидата
    const mutation = useMutation(createCandidate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["candidates"]); // Обновляем список кандидатов
            showSnackbar("Кандидат успешно создан!", "success"); // Успешное сообщение (зелёный)
            navigate("/candidates")
        },
        onError: (error) => {
            console.error("Ошибка при создании кандидата:", error);
            showSnackbar("Ошибка при создании кандидата", "error"); // Сообщение об ошибке (красный)
        },
    });

    const isFormValid = () => {
        return (
            name.trim() &&
            surname.trim() &&
            city &&
            desiredPosition
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isFormValid()) {
            alert("Пожалуйста, заполните все обязательные поля.");
            return;
        }

        // Создаем объект FormData
        const formData = new FormData();

        // Добавляем обязательные поля
        formData.append("first_name", name.trim());
        formData.append("last_name", surname.trim());

        // Добавляем условные поля
        if (patronymic.trim()) {
            formData.append("middle_name", patronymic.trim());
        }

        if (phone) {
            formData.append("phone", phone.trim());
        }

        if (email.trim()) {
            formData.append("email", email.trim());
        }

        if (city?.name) {
            formData.append("city_name", city.name);
        }

        if (vacancy?.length) {
            vacancy.forEach((el) => {
                formData.append("vacancy_ids[]", el.id); // Массив вакансий
            });
        }

        if (birthDate) {
            formData.append("birth_date", birthDate);
        }

        if (desiredPosition.trim()) {
            formData.append("desired_position", desiredPosition.trim());
        }

        if (desiredSalary) {
            formData.append("salary", desiredSalary);
        }

        if (currency?.code) {
            formData.append("salary_currency", currency.code);
        }

        if (photo) {
            formData.append("avatar", photo)
        }

        if (attachedFile) {
            formData.append("cv", attachedFile)
        }

        console.log("Submitting candidate data via FormData:");

        // Отправка данных через мутацию
        mutation.mutate(formData);
    };



    // const handleVacancyScrollEnd = (event) => {
    //     const listboxNode = event.currentTarget;
    //     const bottom =
    //         listboxNode.scrollHeight - listboxNode.scrollTop <=
    //         listboxNode.clientHeight + 10;
    //     if (bottom && hasNextVacanciesPage && !isFetchingNextVacanciesPage) {
    //         fetchNextVacanciesPage();
    //     }
    // };

    const handleCityScrollEnd = (event) => {
        const listboxNode = event.currentTarget;
        const bottom =
            listboxNode.scrollHeight - listboxNode.scrollTop <=
            listboxNode.clientHeight + 10;
        if (bottom && hasNextCityPage && !isFetchingNextCityPage) {
            fetchNextCityPage();
        }
    };

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];

        if (!file) return;

        const maxFileSize = 2 * 1024 * 1024; // 2 MB
        const allowedFormats = ["image/jpeg", "image/png", "image/gif"];

        if (file.size > maxFileSize) {
            showSnackbar("Размер файла не должен превышать 2MB.");
            event.target.value = ""; // Сбрасываем выбор файла
            return;
        }

        if (!allowedFormats.includes(file.type)) {
            showSnackbar("Допустимые форматы: JPG, JPEG, PNG, GIF.");
            event.target.value = ""; // Сбрасываем выбор файла
            return;
        }

        // Если размер и формат в пределах допустимого, сохраняем файл
        setPhoto(file);
        setPhotoPreview(URL.createObjectURL(file)); // Генерируем превью
    };

    const handlePhotoRemove = () => {
        setPhoto(null);
        setPhotoPreview(null);
    };

    const showSnackbar = (message, severity = "error") => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, display: "flex", flexDirection: "column" }}>
            <Paper
                elevation={0}
                sx={{
                    p: 4,
                    borderRadius: 2,
                    backgroundColor: "#FFFFFF",
                    border: "none",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "calc(90vh - 27px)",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2, // Отступ снизу
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        Основная информация
                    </Typography>

                    {/* Кнопка "Прикрепить файл" */}
                    {attachedFile ? (
                        // Отображаем название файла
                        <Box sx={{ display: 'flex', alignItems: 'center', color: '#1F6BC5' }}>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '16px',
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "200px", // Ограничение ширины текста
                                    borderBottom: "1px solid #1F6BC5",
                                    lineHeight: '16px'
                                }}
                            >
                                {attachedFile.name}
                            </Typography>
                            <Button
                                onClick={handleRemoveFile}
                                sx={{
                                    minWidth: "auto",
                                    padding: 0,
                                    color: "#1F6BC5",
                                    width: '20px',
                                    height: '20px'
                                }}
                            >
                                <CloseIcon sx={{ width: '20px' }} />
                            </Button>
                        </Box>
                    ) : (
                        // Кнопка для прикрепления файла
                        <Button
                            component="label"
                            variant="outlined"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "#6BA14A",
                                borderColor: "#6BA14A",
                                textTransform: "none",
                                height: "46px",
                                borderRadius: "8px",
                                "&:hover": {
                                    backgroundColor: "#F1F8E9",
                                    borderColor: "#6BA14A",
                                },
                            }}
                        >
                            <AttachFileIcon sx={{ mr: 1 }} />
                            Прикрепить резюме
                            <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                            />
                        </Button>
                    )}
                </Box>
                <Divider sx={{ mt: 2, mb: 4 }} />

                {/* Загрузка фотографии */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: 'fit-content',
                        gap: '4px',
                        mb: 4
                    }}
                >
                    <Box
                        sx={{
                            width: 100,
                            height: 100,
                            borderRadius: "10px",
                            border: "1px solid #E0E0E0",
                            backgroundColor: "#F9F9F9",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                            position: "relative",
                            cursor: "pointer",
                        }}
                    >
                        {photoPreview ? (
                            <>
                                <img
                                    src={photoPreview}
                                    alt="Фото кандидата"
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                                <IconButton
                                    onClick={handlePhotoRemove}
                                    sx={{
                                        position: "absolute",
                                        top: 4,
                                        right: 4,
                                        backgroundColor: "#fff",
                                        "&:hover": { backgroundColor: "#f5f5f5" },
                                        width: "20px",
                                        height: "20px",
                                    }}
                                >
                                    <CloseIcon sx={{ width: "14px" }} />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <PhotoCamera style={{ fontSize: 40, color: "#BDBDBD" }} />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handlePhotoChange}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        opacity: 0,
                                        cursor: "pointer",
                                    }}
                                />
                            </>
                        )}
                    </Box>
                    <Typography sx={{ color: '#ADB5BD', fontSize: '12px', width: '100%', textAlign: 'center' }}>Изменить фото</Typography>
                </Box>

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                    }}
                >
                    {/* Поля формы */}
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "20px",
                        }}
                    >
                        <LabeledInput
                            label="Имя"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Введите имя"
                            required
                        />
                        <LabeledInput
                            label="Фамилия"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            placeholder="Введите фамилию"
                            required
                        />
                        <LabeledInput
                            label="Отчество"
                            value={patronymic}
                            onChange={(e) => setPatronymic(e.target.value)}
                            placeholder="Введите отчество"
                        />
                        <LabeledInput
                            label="Дата рождения"
                            value={birthDate}
                            onChange={(e) => setBirthDate(e.target.value)}
                            type="date"
                        />
                        <LabeledInput
                            label="Номер телефона"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="+7 ___-___-__-__"
                            mask="+7 (999) 999-99-99" // Маска для телефона
                        />

                        <LabeledInput
                            label="Электронная почта"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Введите электронную почту"
                        />
                    </Box>

                    {/* Автокомплит для городов */}

                    {/* Желаемая должность */}


                    {/* Желаемая зарплата */}
                    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "20px" }}>
                        <CustomAutocomplete
                            label="Город"
                            value={city}
                            onChange={setCity}
                            searchQuery={citySearchQuery}
                            setSearchQuery={setCitySearchQuery}
                            options={cityOptions}
                            isLoading={isCitiesLoading}
                            hasNextPage={hasNextCityPage}
                            fetchNextPage={fetchNextCityPage}
                            ListboxProps={{ onScroll: handleCityScrollEnd }}
                            required
                        />

                        <LabeledInput
                            label="Желаемая должность"
                            value={desiredPosition}
                            onChange={(e) => setDesiredPosition(e.target.value)}
                            placeholder="Введите должность"
                            width={'356px'}
                            required
                        />
                        <LabeledInput
                            label="Желаемая зарплата"
                            value={desiredSalary}
                            onChange={(e) => setDesiredSalary(e.target.value)}
                            placeholder="Введите размер зарплаты"
                            type="number"
                            width={'219px'}
                        />

                        {/* Автокомплит для валют */}
                        <CustomAutocomplete
                            label="Валюта"
                            value={currency}
                            onChange={setCurrency}
                            options={currencyOptions}
                            isLoading={isCurrenciesLoading}
                            width={'94px'}
                            searchQuery={''}
                            setSearchQuery={() => { }}
                        />
                    </Box>

                    {/* Автокомплит для вакансий */}
                    <CustomAutocomplete
                        label="Связать с вакансией"
                        value={vacancy}
                        onChange={setVacancy}
                        fetchNextPage={fetchNextVacanciesPage}
                        hasNextPage={hasNextVacanciesPage}
                        options={vacancyOptions.data}
                        isLoading={isVacanciesLoading}
                        searchQuery={''}
                        setSearchQuery={() => { }}
                        multiple={true}
                    />

                    {/* Кнопка отправки */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "16px", // Отступ между кнопками
                            mt: 3, // Отступ сверху
                        }}
                    >
                        {/* Кнопка "Назад" */}
                        <Button
                            variant="outlined"
                            sx={{
                                width: "136px",
                                height: "56px",
                                borderRadius: "8px",
                                color: "#5A5A5A",
                                borderColor: "#D1D1D1",
                                "&:hover": {
                                    backgroundColor: "#F5F5F5",
                                    borderColor: "#BDBDBD",
                                },
                            }}
                            onClick={() => navigate('/candidates')} // Действие для кнопки "Назад"
                        >
                            Назад
                        </Button>

                        {/* Кнопка "Сохранить" */}
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={!isFormValid()} // Деактивируем кнопку, если форма невалидна
                            sx={{
                                width: "136px",
                                height: "56px",
                                borderRadius: "8px",
                                backgroundColor: "#599D15",
                                color: "#FFFFFF",
                                "&:hover": {
                                    backgroundColor: "#4B8511",
                                },
                                "&.Mui-disabled": {
                                    backgroundColor: "#D1D1D1",
                                    color: "#FFFFFF",
                                },
                            }}
                        >
                            Сохранить
                        </Button>
                    </Box>

                </Box>
            </Paper>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity} // Используем динамический цвет (error или success)
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Container>

    );
};

export default CreateCandidatesPage;
