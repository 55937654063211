// src/services/accountApi.js
import axiosClassic from '../api/interceptors';

export const getCompanies = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) throw new Error("Access token is missing");

    const response = await axiosClassic.get('/api/me', {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    return response.data.data.companies.map((company) => ({
        company_id: company.company_id,
        company_name: company.company_name,
        manager_id: company.manager_id,
        is_hh_connected: company.is_hh_connected,
    }));
};

// Обновленный метод для установки выбранной компании
export const setSelectedCompany = ({ company_id, manager_id, is_hh_connected }) => {
    localStorage.setItem('company_id', company_id);
    localStorage.setItem('manager_id', manager_id);
    localStorage.setItem('is_hh_connected', is_hh_connected)
};
