import React, { useState } from 'react';
import { Box, Typography, Paper, Button, Radio, RadioGroup, FormControlLabel, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCompanies, setSelectedCompany } from '../../services/accountApi';

const AccountSelectionPage = () => {
    const [selectedAccount, setSelectedAccount] = useState(null);
    const navigate = useNavigate();

    // Используем useQuery для получения данных о компаниях
    const { data: companies, isLoading, error } = useQuery(['companies'], getCompanies);

    const handleAccountChange = (event) => {
        setSelectedAccount(event.target.value);
    };

    const handleLogin = () => {
        if (selectedAccount) {
            const selectedCompany = companies.find((company) => company.manager_id.toString() === selectedAccount);

            if (selectedCompany) {
                // Сохраняем `company_id` и `manager_id` через `setSelectedCompany`
                setSelectedCompany({
                    company_id: selectedCompany.company_id,
                    manager_id: selectedCompany.manager_id,
                    is_hh_connected: selectedAccount.is_hh_connected,
                });

                // Перенаправляем пользователя на главную страницу
                window.location.href = '/'
            }
        } else {
            alert('Пожалуйста, выберите аккаунт перед входом.');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Предотвращаем отправку формы по умолчанию
            handleLogin();
        }
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography color="error">Ошибка при загрузке списка компаний.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 5 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Добро пожаловать
            </Typography>
            <Typography variant="body1" sx={{ color: '#A2A1A8', mb: 4 }}>
                Выберите аккаунт
            </Typography>

            <Paper
                elevation={3}
                sx={{
                    width: '400px',
                    padding: '20px',
                    borderRadius: '12px',
                    textAlign: 'center',
                }}
                onKeyDown={handleKeyDown} // Добавляем обработчик события
            >
                <RadioGroup value={selectedAccount} onChange={handleAccountChange}>
                    {companies.map((company) => (
                        <FormControlLabel
                            key={company.manager_id}
                            value={company.manager_id.toString()}
                            control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />}
                            label={company.company_name}
                            sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                        />
                    ))}

                </RadioGroup>

                <Button
                    variant="contained"
                    onClick={handleLogin}
                    sx={{
                        mt: 2,
                        backgroundColor: '#599D15',
                        padding: '12px 0',
                        width: '100%',
                        borderRadius: '8px',
                        '&:hover': {
                            backgroundColor: '#4A8B12',
                        },
                    }}
                >
                    Войти
                </Button>
            </Paper>
        </Box>
    );
};

export default AccountSelectionPage;
