import React, { useState } from "react";
import { Box, Button } from "@mui/material";

const DriverLicenseSection = ({ licenseData, setLicenseData }) => {
    const categories = ["A", "B", "C", "D", "E", "BE", "CE", "DE", "TM", "TB"];

    const handleCategoryToggle = (category) => {
        if (licenseData.includes(category)) {
            // Удалить категорию, если она уже выбрана
            setLicenseData(licenseData.filter((item) => item !== category));
        } else {
            // Добавить категорию, если она не выбрана
            setLicenseData([...licenseData, category]);
        }
    };

    return (
        <Box sx={{ mb: 4 }}>
            <Box sx={{ fontWeight: 500, mb: '20px', color: "#151813", fontSize: "16px" }}>
                Категория прав
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {categories.map((category) => (
                    <Button
                        key={category}
                        variant={licenseData.includes(category) ? "contained" : "outlined"}
                        onClick={() => handleCategoryToggle(category)}
                        sx={{
                            minWidth: "36px",
                            maxWidth: '36px',
                            height: "36px",
                            borderRadius: "8px",
                            fontWeight: 500,
                            fontSize: "16px",
                            color: licenseData.includes(category) ? "#FFF" : "#151813",
                            backgroundColor: licenseData.includes(category) ? "#599D15" : "#FFF",
                            borderColor: "#BDBDBD",
                            "&:hover": {
                                backgroundColor: licenseData.includes(category)
                                    ? "#4A8512"
                                    : "rgba(89, 157, 21, 0.1)",
                            },
                        }}
                    >
                        {category}
                    </Button>
                ))}
            </Box>
        </Box>
    );
};

export default DriverLicenseSection;
