import { MenuItem } from "@mui/material";
import PopUpWindow from "../popUpWindow";

const StagePopUpWindow = ({ anchorEl, onClose, handleRefusal, onStageChange, negotiationId, currStageId, stages}) => {

    const handleStageChange = (stageId) => {
        if (stageId === currStageId) {
            return;
        }
        try {
            onStageChange(negotiationId, stageId); // Используем negotiation_id для изменения этапа
            onClose();
        } catch (error) {
            console.error("Ошибка при смене этапа подбора:", error);
        }
    };

    return (
        <PopUpWindow 
            anchorEl={anchorEl} 
            open = {Boolean(anchorEl)} 
            onClose = {onClose}
            onClick = {handleStageChange}
            stages = {stages}
            sx = {{
                "& .MuiPaper-root": {
                    borderRadius: "8px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                },
            }}
        >
            {/* Pass refusal exceptional stage*/}
            <MenuItem
                key={'refusal'}
                onClick={handleRefusal}
            >
                Отказ
            </MenuItem>
        </PopUpWindow>
    )
}

export default StagePopUpWindow;