import React, { memo } from "react";
import {
    Box,
    Typography,
    Button,
} from '@mui/material';
import { ArrowDownIcon, ArrowUpIcon, MenuIcon, LockIcon } from "../svgComponents";
import ActiveDeleteIcon from '../../assets/icons/activeDeleteIcon.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { onDragEnd } from "../../utils/dragAndDrop";

const SecondStep = memo(({stages, setStages }) => {
    const changeIndexes = (stageStartId, stageEndId) => {
        const newStages = [...stages];
        const temp = newStages[stageStartId].name;
        newStages[stageStartId].name = newStages[stageEndId].name;
        newStages[stageEndId].name = temp;
        return newStages;
    }

    const handleDragEnd = ({elementStartIdx : stageStartId, elementEndIdx : stageEndId}) => {
        const newStages = changeIndexes(stageStartId, stageEndId);
        setStages(newStages);
    }

    const moveStage = (index, direction) => {
        const newStages = changeIndexes(index, direction === "up" ? index - 1 : index + 1);
        setStages(newStages);
    };

    const deleteStage = (index) => {
        const newStages = stages.filter((_, idx) => idx !== index);

        // Обновляем порядок после удаления
        newStages.forEach((stage, idx) => {
            stage.order = idx + 1;
        });

        setStages(newStages);
    };

    return (
        <>
            <Typography sx={{ color: '#A2A1A8', fontSize: '14px', marginBottom: '16px' }}>Название этапа</Typography>
            <Box>
                <Box
                    key={'first'}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #E0E0E0',
                        py: 2
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                        <MenuIcon sx = {{width: "24px", height : "24px"}} svgSx={{width: "15px", height: "16px", color: "rgba(162, 161, 168, 1)"}}/>
                        <Typography sx={{color: "rgba(162, 161, 168, 1)"}}>Новый</Typography>
                    </Box>
                    <LockIcon sx={{width: "30px", height: "30px"}} svgSx={{width: "24px", height: "20px", color : "rgba(162, 161, 168, 1)"}}/>
                </Box>
                <DragDropContext onDragEnd={(e) => onDragEnd(e, handleDragEnd)}>
                    <Droppable droppableId={"0"}>
                        {(provided, snapshot) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="droppable"
                                sx = {{
                                    display: 'flex',
                                    border: snapshot.isDraggingOver ? '1px solid rgba(89, 157, 21, 1)' : 'none',
                                    transition: 'background-color 0.2s ease',
                                    flexDirection: 'column',
                                }}
                            >
                                {stages.map((stage, index) => (
                                    <Draggable key={stage.order} draggableId={index.toString()} index={index} >
                                        {(provided) => (
                                            <Box
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    borderBottom: '1px solid #E0E0E0',
                                                    py: 2
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                                                    <MenuIcon sx = {{width: "24px", height : "24px"}} svgSx={{width: "16px", height: "14px", color: "rgba(21, 24, 19, 1)"}}/>
                                                    <Typography>{stage.name}</Typography>
                                                </Box>
                                                    <Box sx={{display: "flex", gap: "24px", alignItems: "center"}}>
                                                        <Box sx={{display: "flex", gap: "4px"}}>
                                                            <ArrowUpIcon 
                                                                sx={{width: "20px", height: "20px", opacity : index > 0 ? 1 : 0.3, cursor: index > 0 ? "pointer" : "grab"}} 
                                                                svgSx = {{color: "rgba(21, 24, 19, 1)", width: "12px", height: "20px"}}
                                                                onClick={index > 0 ? () => moveStage(index, "up") : null}
                                                            />
                                                            <ArrowDownIcon 
                                                                sx={{width: "20px", height: "20px", opacity : index < stages.length - 1 ? 1 : 0.3, cursor: index < stages.length - 1 ? "pointer" : "grab"}} 
                                                                svgSx = {{color: "rgba(21, 24, 19, 1)", width: "12px", height: "20px"}}
                                                                onClick={index < stages.length - 1 ? () => moveStage(index, "down") : null}
                                                            />
                                                    </Box>
                                                    <Button
                                                        sx={{
                                                            minWidth: 'unset',
                                                            background: '#FFF3F2',
                                                            padding: '4px',
                                                            border: '1px solid #FF7E7A',
                                                            borderRadius: '8px'
                                                        }}
                                                        onClick={() => deleteStage(index)}
                                                    >
                                                        <img src={ActiveDeleteIcon} alt="delete" style={{ cursor: 'pointer' }} />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
                <Box
                    key={'last'}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #E0E0E0',
                        py: 2
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                        <MenuIcon sx = {{width: "24px", height : "24px"}} svgSx={{width: "15px", height: "16px", color: "rgba(162, 161, 168, 1)"}}/>
                        <Typography sx={{color: "rgba(162, 161, 168, 1)"}}>Принял оффер</Typography>
                    </Box>
                    <LockIcon sx={{width: "30px", height: "30px"}} svgSx={{width: "24px", height: "20px", color : "rgba(162, 161, 168, 1)"}}/>
                </Box>
            </Box>
        </>
    );
});

export default SecondStep;
