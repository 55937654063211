import axiosClassic from "../api/interceptors";

export const fetchProfessionalRoles = async () => {
    try {
        const response = await axiosClassic.get("/api/hh/professional-roles");
        return response.data;
    } catch (error) {
        console.error("Ошибка при получении профессиональных ролей:", error);
        return [];
    }
};


export const fetchLanguages = async () => {
    try {
        const response = await axiosClassic.get("https://api.hh.ru/languages");
        return response.data;
    } catch (error) {
        console.error("Ошибка при получении языков:", error);
        return [];
    }
};

export const publishToHH = async ({ publishData, company_id, vacancy_id }) => {
    try {
        const response = await axiosClassic.post(`api/company/${company_id}/vacancies/${vacancy_id}/hh`, publishData);
        return response.data;
    } catch (error) {
        console.error("Ошибка при публикации на HeadHunter:", error);
        throw error; // Если нужно обрабатывать ошибку на уровне вызова
    }
};

export const closeHHNotify = async () => {
    try {
        const response = await axiosClassic.post(`api/hh/notified`)
        return response.data
    } catch (error) {
        console.error("Ошибка при закрытии уведомления привязки на HeadHunter:", error);
        throw error;
    }
}

