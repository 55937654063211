import React, { useState } from "react";
import {
    Box,
    TextField,
    Chip,
    Typography,
    FormControl,
    FormLabel,
} from "@mui/material";

const SkillsSection = ({ skills, setSkills }) => {
    const [inputValue, setInputValue] = useState("");

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && inputValue.trim() !== "") {
            e.preventDefault(); // Отключить стандартное поведение Enter
            if (skills.length < 15) {
                setSkills([...skills, { name: inputValue.trim() }]);
                setInputValue(""); // Очистить поле ввода
            } else {
                alert("Можно добавить не более 15 навыков");
            }
        }
    };

    const handleDeleteSkill = (index) => {
        const updatedSkills = skills.filter((_, i) => i !== index);
        setSkills(updatedSkills);
    };

    return (
        <FormControl fullWidth sx={{ mb: 3 }}>
            <FormLabel sx={{ fontWeight: 500, mb: 1, color: "#151813", fontSize: "16px" }}>
                Навыки
            </FormLabel>
            <Typography sx={{ mb: 1, fontSize: "14px", color: "#ADB5BD" }}>
                Укажите до 15 — будем рекомендовать вакансию людям с этими навыками
            </Typography>
            <TextField
                fullWidth
                placeholder="Напишите свой вариант"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                sx={{
                    mb: 2,
                    backgroundColor: "#F8F9F9",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        "& fieldset": {
                            borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                            borderColor: "#BDBDBD",
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "#599D15",
                        },
                    },
                }}
            />
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {skills.map((skill, index) => (
                    <Chip
                        key={index}
                        label={skill.name}
                        onDelete={() => handleDeleteSkill(index)}
                        sx={{
                            backgroundColor: "#F8F9F9",
                            border: "1px solid #BDBDBD",
                            fontWeight: 500,
                            "& .MuiChip-deleteIcon": {
                                color: "#ADB5BD",
                                "&:hover": {
                                    color: "#FF3B30",
                                },
                            },
                        }}
                    />
                ))}
            </Box>
        </FormControl>
    );
};

export default SkillsSection;
