import React from 'react';
import {
    Box,
    Typography,
    TextField,
} from '@mui/material';

const CustomStepper = ({ steps, activeStep }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mb: 4 }}>
            {steps.map((label, index) => (
                <React.Fragment key={label}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '124px' }}>
                        <Box
                            sx={{
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                backgroundColor:
                                    activeStep > index ? '#73C18D' : activeStep === index ? '#599D15' : '#E0E0E0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: activeStep > index ? '#fff' : activeStep === index ? '#fff' : '#000',
                                fontWeight: 400,
                                fontSize: '24px',
                                zIndex: 1,
                            }}
                        >
                            {activeStep > index ? (
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M18.4933 6.93502C18.8053 7.20743 18.8374 7.68122 18.565 7.99325L10.7079 16.9933C10.5654 17.1564 10.3594 17.25 10.1429 17.25C9.9263 17.25 9.72031 17.1564 9.57788 16.9933L6.43502 13.3933C6.16261 13.0812 6.19473 12.6074 6.50677 12.335C6.8188 12.0626 7.29259 12.0947 7.565 12.4068L10.1429 15.3596L17.435 7.00677C17.7074 6.69473 18.1812 6.66261 18.4933 6.93502Z"
                                        fill="white"
                                    />
                                </svg>
                            ) : (
                                index + 1
                            )}
                        </Box>
                        <Typography
                            sx={{
                                color: activeStep > index ? '#14AE5C' : activeStep === index ? '#599D15' : '#A2A1A8',
                                fontWeight: 400,
                                fontSize: '14px',
                                textAlign: 'center',
                                mt: 1,
                            }}
                        >
                            {label}
                        </Typography>
                    </Box>

                    {index < steps.length - 1 && (
                        <Box
                            sx={{
                                flex: 1,
                                height: 2,
                                backgroundColor: activeStep > index ? '#73C18D' : '#E0E0E0',
                                mx: 2,
                                position: 'relative',
                                top: 21,
                            }}
                        />
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};


export default CustomStepper;