import React, { useState, useEffect, memo } from 'react';
import {
    Container,
    Box,
    Button,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Divider,
    Typography,
    TextField,
    Tooltip
} from '@mui/material';
import closeIcon from '../../assets/icons/closeIcon.svg';
import CustomStepper from '../../components/main/CustomStepper';
import FirstStep from '../../components/vacancy/FirstStep';
import SecondStep from '../../components/vacancy/SecondStep';
import PlusIcon from "../../components/main/PlusIcon";
import ThirdStep from '../../components/vacancy/ThirdStep';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createVacancy } from "../../services/vacancyApi"; // Ваш API для создания вакансий
import { useNavigate, useLocation } from "react-router-dom";


const steps = ['Основная информация', 'Собеседование', 'Публикация'];

const CreateVacancyPage = () => {
    const location = useLocation();
    const externalVacancyData = location.state; // Получаем данные, переданные через navigate
    const [isLinkModalOpen, setIsLinkModalOpen] = useState(false); // Состояние для модального окна
    const [includeExternalFields, setIncludeExternalFields] = useState(false); // Флаг для включения external полей
    const [activeStep, setActiveStep] = useState(0);

    const [name, setName] = useState(externalVacancyData?.name || ''); // Предзаполнение
    const [text, setText] = useState(externalVacancyData?.description || '');

    // Состояние для всех шагов
    const [salaryFrom, setSalaryFrom] = useState(externalVacancyData?.salary_from || '');
    const [salaryTo, setSalaryTo] = useState(externalVacancyData?.salary_to || '');
    const [currency, setCurrency] = useState(externalVacancyData?.salary_currency || 'KZT');
    const [experience, setExperience] = useState(externalVacancyData?.experience || 'noExperience');
    const [employmentType, setEmploymentType] = useState(externalVacancyData?.employment || 'full');
    const [workSchedule, setWorkSchedule] = useState(externalVacancyData?.schedule || 'fullDay');
    const [taxType, setTaxType] = useState(externalVacancyData?.salary_gross || true);
    const [city, setCity] = useState(externalVacancyData?.city_name ? { name: externalVacancyData.city_name, id: externalVacancyData.hh_area_id } : null);

    const [department, setDepartment] = useState(null)
    const [accept_temporary, set_accept_temporary] = useState(externalVacancyData?.accept_temporary || false);

    const [stages, setStages] = useState([]);


    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleNext = () => setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));

    const handleBack = () => setActiveStep((prev) => Math.max(prev - 1, 0));

    const queryClient = useQueryClient();
    const navigate = useNavigate();


    const mutation = useMutation(createVacancy, {
        onSuccess: () => {
            // Обновляем кэш вакансий после успешного создания
            queryClient.invalidateQueries(["vacancies"]);
            alert("Вакансия успешно создана!");
            navigate("/");
        },
        onError: (error) => {
            alert("Ошибка при создании вакансии:", error);
        },
    });


    const handleCreate = () => {
        // Подготовка данных для отправки
        const body = {
            name,
            description: text,
            salary_from: salaryFrom ? salaryFrom : null,
            salary_to: salaryTo ? salaryTo : null,
            salary_gross: Boolean(taxType),
            salary_currency: currency,
            city_name: city?.name,
            hh_area_id: city?.id,
            recruiter_id: localStorage.getItem('manager_id'),
            department_id: department?.id,
            experience,
            schedule: workSchedule,
            employment: employmentType,
            accept_temporary,
        };

        if (stages.length > 0) {
            body.stages = stages.map(({ name, order }) => ({ name, order }));
        }

        // Добавляем external_source и external_id, если выбран вариант "Связать"
        if (includeExternalFields) {
            body.external_source = externalVacancyData.external_source;
            body.external_id = externalVacancyData.external_id;
        }

        mutation.mutate(body); // Отправляем запрос
    };



    const handleAddStage = (stageName) => {
        const newStage = {
            name: stageName,
            order: stages.length + 1
        };
        setStages([...stages, newStage]);
    };


    // Открытие модального окна, если is_vacancy_owner === true
    useEffect(() => {
        if (externalVacancyData?.is_vacancy_owner) {
            setIsLinkModalOpen(true);
        }
    }, [externalVacancyData]);

    const handleLinkVacancy = () => {
        setIncludeExternalFields(true); // Устанавливаем флаг для включения external полей
        setIsLinkModalOpen(false); // Закрываем модальное окно
    };



    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <FirstStep
                        name={name}
                        setName={setName}
                        salaryFrom={salaryFrom}
                        setSalaryFrom={setSalaryFrom}
                        salaryTo={salaryTo}
                        setSalaryTo={setSalaryTo}
                        currency={currency}
                        setCurrency={setCurrency}
                        experience={experience}
                        setExperience={setExperience}
                        employmentType={employmentType}
                        setEmploymentType={setEmploymentType}
                        workSchedule={workSchedule}
                        setWorkSchedule={setWorkSchedule}
                        taxType={taxType}
                        setTaxType={setTaxType}
                        text={text}
                        setText={setText}
                        city={city}
                        setCity={setCity}
                        department={department}
                        setDepartment={setDepartment}
                        accept_temporary={accept_temporary}
                        set_accept_temporary={set_accept_temporary}
                    />
                );
            case 1:
                return <>
                    <AddStageButton open={open} handleOpen={handleOpen} />
                    <SecondStep stages={stages} setStages={setStages} handleOpen={handleOpen} />
                </>
            case 2:
                return (
                    <ThirdStep
                        name={name}
                        salaryFrom={salaryFrom}
                        salaryTo={salaryTo}
                        currency={currency}
                        experience={experience}
                        employmentType={employmentType}
                        workSchedule={workSchedule}
                        taxType={taxType}
                        text={text}
                        city={city}
                        department={department}
                        accept_temporary={accept_temporary}
                        stages={stages}
                        handleCreate={handleCreate}
                        handleBack={handleBack}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
            <CustomStepper steps={steps} activeStep={activeStep} />

            <Paper
                elevation={0}
                sx={{
                    p: 4,
                    borderRadius: 2,
                    backgroundColor: '#FFFFFF',
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 'calc(90vh - 25px)',
                }}
                style={activeStep === 2 && { background: 'transparent', padding: 0 }}
            >
                {renderStepContent(activeStep)}
            </Paper>

            {open && (
                <DialogModalWindow handleAddStage = {handleAddStage} handleClose={handleClose} />
            )}

            {activeStep < steps.length - 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Button
                        variant="outlined"
                        sx={{ marginRight: 2, height: '56px', boxShadow: 'none', borderRadius: '8px', width: '202px', color: '#599D15', borderColor: '#599D15', fontSize: '16px', fontWeight: '400' }}
                        onClick={handleBack}
                        disabled={activeStep === 0}
                    >
                        Назад
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: !name || !city || !department ? '#E0E0E0' : '#599D15',
                            color: !name || !city || !department ? '#A0A0A0' : 'white',
                            height: '56px',
                            boxShadow: 'none',
                            borderRadius: '8px',
                            width: '202px',
                            fontSize: '16px',
                            fontWeight: '400'
                        }}
                        onClick={handleNext}
                        disabled={!name || !city || !department} // Проверка на заполненность
                    >
                        Перейти к {activeStep + 2} шагу
                    </Button>

                </Box>
            )}

            <Dialog open={isLinkModalOpen} onClose={() => setIsLinkModalOpen(false)} fullWidth maxWidth="xs" PaperProps={{ sx: { borderRadius: '12px', width: '352px', padding: '24px' } }}>
                <DialogTitle sx={{ display: 'flex', alignItems: 'center', padding: 0, justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        <svg style={{ marginRight: '16px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.1213 9.87891L9.8787 14.1215" stroke="#151813" stroke-width="1.5" stroke-linecap="round" />
                            <path d="M10.3332 7.00003L11.9999 5.33336C13.8409 3.49239 16.8256 3.49239 18.6666 5.33335V5.33335C20.5076 7.17431 20.5076 10.1591 18.6666 12.0001L16.9999 13.6667" stroke="#151813" stroke-width="1.5" stroke-linecap="round" />
                            <path d="M13.6665 17L11.9999 18.6666C10.1589 20.5076 7.17411 20.5076 5.33315 18.6666V18.6666C3.49219 16.8257 3.49219 13.8409 5.33315 11.9999L6.99983 10.3333" stroke="#151813" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '20px', lineHeight: '30px', padding: 0 }}>
                            Связать вакансию?
                        </Typography>
                    </div>
                    <Tooltip title="Все новые отклики будут автоматически перенаправляться на платформу tapHR" arrow>
                        <IconButton>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1183_11326)">
                                    <circle cx="9" cy="5.25" r="0.75" fill="#868C92" />
                                    <path d="M8.25 7.5H9V12.75M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#868C92" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1183_11326">
                                        <rect width="18" height="18" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <DialogContent sx={{ marginTop: 2, marginBottom: 3, padding: 0 }}>
                    <Typography sx={{ color: '#868C92', fontSize: '14px', lineHeight: '18px' }}>
                        Мы обнаружили, что вакансия из HeadHunter принадлежит вашей компании. Хотите связать её с текущей вакансией?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ padding: 0 }}>
                    <Button
                        variant="outlined"
                        onClick={() => setIsLinkModalOpen(false)}
                        sx={{ color: '#599D15', borderColor: '#599D15', width: '148px', height: '46px', borderRadius: '8px' }}
                    >
                        Отменить
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleLinkVacancy}
                        sx={{ backgroundColor: '#599D15', color: 'white', width: '148px', height: '46px', borderRadius: '8px' }}
                    >
                        Связать
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

const AddStageButton = memo(({ open, handleOpen }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: 20, lineHeight: '24px' }}>Этапы собеседования</Typography>
            <Button
                variant="outlined"
                startIcon={<PlusIcon />}
                key = {open}
                onClick={handleOpen}
                sx={{ color: '#599D15', borderColor: '#599D15', width: '164px', height: '44px', borderRadius: '8px', fontSize: '14px', fontWeight: 400, textTransform: 'none' }}
            >
                Добавить этап
            </Button>
        </Box>
    )
});


const DialogModalWindow = ({ handleClose, handleAddStage }) => {
    const [newStageName, setNewStageName] = useState("");
    const handleSendingText = () => {
        if (!newStageName) {
            return; // dont sand anythin if newStageName is empty
        }
        handleAddStage(newStageName); // send newStageName to parent component
        setNewStageName(""); // clear newStageName
        handleClose(); // close modal window
    }


    return (
        <Dialog open={true} onClose={handleClose} fullWidth maxWidth="xs" PaperProps={{ sx: { borderRadius: '12px', width: '397px', padding: '24px' } }}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0, marginBottom: '12px' }}>
                <Typography variant="h6" sx={{ fontWeight: 500, fontSize: 20 }}>
                    Добавить этап
                </Typography>
                <IconButton onClick={handleClose} sx={{ color: '#000' }}>
                    <img src={closeIcon} alt="Close" style={{ width: '16px', height: '16px' }} />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: 0 }}>
                <TextField
                    fullWidth
                    label="Название этапа"
                    autoFocus={true}
                    autoComplete='off'
                    variant="outlined"
                    value={newStageName}
                    onChange={(e) => setNewStageName(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                            handleSendingText();
                        }
                    }}
                    sx={{
                        backgroundColor: '#F8F9F9',
                        borderRadius: '8px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#E0E0E0',
                            },
                            '&:hover fieldset': {
                                borderColor: '#BDBDBD',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#599D15',
                            },
                        },
                        mt: 2,
                    }}
                >
                    {/* Добавьте ваши опции этапов здесь */}
                </TextField>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', mt: 3, padding: 0 }}>
                <Button onClick={handleClose} variant="outlined" sx={{ flex: 1, color: '#599D15', borderColor: '#599D15', height: '44px', borderRadius: '8px', boxShadow: 'none' }}>
                    Отменить
                </Button>
                <Button
                    onClick={handleSendingText}
                    variant="contained"
                    sx={{ flex: 1, backgroundColor: '#599D15', color: '#fff', height: '44px', borderRadius: '8px', boxShadow: 'none' }}>
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default CreateVacancyPage;
