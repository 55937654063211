// NavigationItem.js
import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

const NavigationItem = ({ icon, label, isActive, isSubActive, onClick, arrow, isDisabled, hidden }) => {
    if (hidden) return null; // Условное рендеринг

    console.log(isSubActive)
    if (isSubActive) {
        isActive = true;
    }

    return (
        <ListItem
            button
            onClick={onClick}
            sx={{
                paddingY: 1,
                paddingLeft: 2,
                cursor: 'pointer',
                marginBottom: '16px',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                pointerEvents: isDisabled ? 'none' : 'auto',
                backgroundColor: isActive ? '#F0F9E7' : 'transparent',
                borderLeft: isActive && !isSubActive ? '3px solid rgba(89, 157, 21, 1)' : '3px solid transparent',
                '&:hover': {
                    backgroundColor: isActive ? '#F0F9E7' : (isDisabled ? 'transparent' : '#F8F9F9'),
                },
            }}
        >
            <ListItemIcon sx={{ minWidth: 24, marginRight: '16px' }}>
                <img
                    src={icon}
                    alt={`${label} icon`}
                    style={{
                        filter: isActive
                            ? 'invert(38%) sepia(74%) saturate(472%) hue-rotate(66deg) brightness(90%) contrast(83%)'
                            : 'none',
                        opacity: isDisabled ? 0.5 : 1,
                    }}
                />
            </ListItemIcon>
            <ListItemText
                primary={label}
                primaryTypographyProps={{ fontWeight: "600", color: isActive ? '#599D15' : isDisabled ? "rgba(226, 228, 231, 1)" : '#151813', width: 'fit-content' }}
            />
            {arrow && arrow}
        </ListItem>
    );
};

export default NavigationItem;
