// src/services/dictionariesApi.js
import axiosClassic from "../api/interceptors";

export const fetchCities = async (query, page = 1, perPage = 10) => {
    const params = {
        page,
        per_page: perPage,
        ...(query ? { name: query } : {}),
    };

    const response = await axiosClassic.get(`/api/hh/areas`, { params });

    // Убедитесь, что возвращается массив городов
    return {
        current_page: response.data.current_page,
        per_page: response.data.per_page,
        total: response.data.total,
        data: response.data.data || [], // Гарантируем, что это массив
    };
};

// src/services/dictionariesApi.js
export const fetchCurrencies = async () => {
    const response = await axiosClassic.get(`/api/hh/dictionaries`, {
        params: {
            type: 'currency',
        },
    });
    return response.data.data; // Теперь возвращаем data из ответа
};



export const fetchDepartments = async (query, page = 1, perPage = 10) => {
    const companyId = localStorage.getItem('company_id'); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    const params = {
        page,
        per_page: perPage,
        ...(query ? { name: query } : {}), // Добавляем name только если query существует
    };

    const response = await axiosClassic.get(`/api/company/${companyId}/departments`, { params });
    return response.data.data; // Возвращаем только данные об отделах
};



