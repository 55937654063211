import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CandidatesList from './CandidatesList';
import CandidateDetails from './CandidateDetails';
import FilterBar from '../../components/vacancy/FilterBar';
import { useNavigate } from 'react-router-dom';



const CandidatesPage = () => {
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState('');
    const [selectedCandidateId, setSelectedCandidateId] = useState(null); // Первый кандидат выбран по умолчанию


    const handleClickOpen = () => {
        navigate('/candidates/create')
    }

    return (
        <Box sx={{ p: 3, backgroundColor: '#F5F5F5', minHeight: '90vh' }}>
            <FilterBar label={"Создать кандидата"} hiddenSelects={true} handleOpen={handleClickOpen} />
            <Grid container spacing={3}>
                {/* Левый блок со списком */}
                <Grid 
                    item 
                    xs={4}
                    sx={{
                        transition: 'all 0.15s ease-in-out',
                        position: 'relative',
                    }}
                >
                    <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 2, boxShadow: 1, p: 2 }}>
                        <CandidatesList
                            selectedCandidateId={selectedCandidateId}
                            onSelectCandidateId={setSelectedCandidateId}
                            onSearch={setSearchValue} // Передаём функцию поиска
                        />
                    </Box>
                </Grid>

                {/* Правый блок с деталями */}
                <Grid item xs={8}>
                    <Box>
                        <CandidateDetails candidateId={selectedCandidateId} queryKey={["details_active_candidate", selectedCandidateId]} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CandidatesPage;
