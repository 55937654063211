import axiosClassic from "../api/interceptors";

export const createComment = async (commentData) => {
    const companyId = localStorage.getItem('company_id'); // Retrieve company ID from localStorage
    if (!companyId) throw new Error("Company ID not found");

    try {
        const response = await axiosClassic.post(
            `/api/company/${companyId}/candidates/${commentData.candidateId}/comment`,
            {   
                text : commentData.text,
                is_visible: commentData.is_visible
            }, // Sending the comment text in the body
            {
                headers: {
                    "Content-Type": "application/json", // Specify JSON content type
                },
            }
        );
        return response?.data?.data; // Return the API response
    } catch (error) {
        console.error("Error creating comment:", error);
        throw error; // Rethrow the error for further handling
    }
};

export const updateComment = async (commentData) => {
    const companyId = localStorage.getItem('company_id'); // Retrieve company ID from localStorage
    if (!companyId) throw new Error("Company ID not found");

    try {
        const response = await axiosClassic.put(
            `/api/company/${companyId}/candidates/${commentData.candidateId}/comment/${commentData.commentId}`,
            {   
                text : commentData.text
            }, // Sending the updated comment text in the body
            {
                headers: {
                    "Content-Type": "application/json", // Specify JSON content type
                },
            }
        );
        return response?.data?.data; // Return the API response
    } catch (error) {
        console.error("Error updating comment:", error);
        throw error; // Rethrow the error for further handling
    }
}

export const deleteComment = async (commentData) => {
    const companyId = localStorage.getItem('company_id'); // Retrieve company ID from localStorage
    if (!companyId) throw new Error("Company ID not found");

    try {
        const response = await axiosClassic.delete(
            `/api/company/${companyId}/candidates/${commentData.candidateId}/comment/${commentData.commentId}`
        );
        return response?.data?.data; // Return the API response
    } catch (error) {
        console.error("Error deleting comment:", error);
        throw error; // Rethrow the error for further handling
    }
}