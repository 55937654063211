import React from "react";
import { Box, Typography, TextField } from "@mui/material";
import InputMask from "react-input-mask";

const LabeledInput = ({ label, value, onChange, width, required, mask, ...props }) => {
    return (
        <Box sx={{ mb: 3, width }}>
            <Typography
                sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#151813",
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {label}
                {required && (
                    <Typography
                        component="span"
                        sx={{
                            color: "#FF3B30",
                            fontWeight: 500,
                            marginLeft: "4px",
                        }}
                    >
                        *
                    </Typography>
                )}
            </Typography>
            {mask ? (
                <InputMask
                    mask={mask}
                    value={value}
                    onChange={onChange}
                >
                    {(inputProps) => (
                        <TextField
                            {...inputProps}
                            fullWidth
                            variant="outlined"
                            {...props}
                            sx={{
                                backgroundColor: "#F8F9F9",
                                borderRadius: "8px",
                                "& .MuiOutlinedInput-root": {
                                    height: "52px",
                                    "& fieldset": {
                                        borderColor: "transparent",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#BDBDBD",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#599D15",
                                    },
                                },
                            }}
                        />
                    )}
                </InputMask>
            ) : (
                <TextField
                    fullWidth
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    {...props}
                    sx={{
                        backgroundColor: "#F8F9F9",
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": {
                            height: "52px",
                            "& fieldset": {
                                borderColor: "transparent",
                            },
                            "&:hover fieldset": {
                                borderColor: "#BDBDBD",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#599D15",
                            },
                        },
                    }}
                />
            )}
        </Box>
    );
};

export default LabeledInput;
