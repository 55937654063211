import React, { memo, useState } from "react";
import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { useQueryClient } from '@tanstack/react-query';
import { getTimeStampFomatted } from "../../utils/formatedDate";
import StagePopUpWindow from "./candidateStagePopUpWindow";

const DropdownButton = ({isActive, handleRefusal, onStageChange, negotiationId, currStageId, stages}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); // Toggle the dropdown
    };

    const handleClose = () => {
        setAnchorEl(null); // Close the dropdown
    };

    return (
        <Box>
            {/* Button */}
            <Box
                component="button"
                onClick={handleClick}
                sx={{
                    height: "46px",
                    padding: "12px 20px",
                    gap: "4px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    backgroundColor: anchorEl ? "rgba(69, 122, 16, 1)" : "rgba(89, 157, 21, 1)",
                    border: anchorEl ? "2px solid rgba(89, 157, 21, 0.5)" : "2px solid transparent",
                    "&:hover": {
                        backgroundColor: "rgba(80, 140, 19, 1)",
                    },
                    "&:active": {
                        backgroundColor: "rgba(69, 122, 16, 1)",
                        border: "2px solid rgba(89, 157, 21, 0.5)",
                    },
                    color: "rgba(255, 255, 255, 1)",
                    display: "flex",
                    alignItems: "center",
                    visibility: isActive ? "visible" : "hidden",
                }}
            >
                <RefreshIcon sx={{ width: "18px", height: "18px", color: "rgba(255, 255, 255, 1)" }} />
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "20px",
                    }}
                >
                    Сменить Этап
                </Typography>
            </Box>

            {/* Dropdown */}
            <StagePopUpWindow 
                anchorEl={anchorEl} 
                onClose={handleClose} 
                handleRefusal={handleRefusal} 
                onStageChange={onStageChange} 
                negotiationId={negotiationId} 
                currStageId={currStageId} 
                stages = {stages}
            />
        </Box>
    );
}

const NegotiationActivityUi = memo(({ currVacancyIdx, handleRefusal, onStageChange, negotiationId, currStageId, stages, vacancyId }) => {
    const queryClient = useQueryClient();
    const candidate = queryClient.getQueryData(["active_candidate"]);
    const negotiations = candidate?.negotiations ?? [];

    return (
        <>
            <Box sx={{ padding: "24px", backgroundColor: "#FFFFFF" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "20px",
                                lineHeight: "24px",
                                color: "rgba(21, 24, 19, 1)",
                            }}
                        >
                            Активность
                    </Typography>
                    <DropdownButton isActive = {currVacancyIdx < negotiations.length && negotiations[currVacancyIdx].id === vacancyId} handleRefusal={handleRefusal} onStageChange={onStageChange} negotiationId={negotiationId} currStageId={currStageId} stages={stages}/>
                </Box>
                <Divider sx={{ marginTop: "16px", left: "24px", right: "24px", backgroundColor: "rgba(235, 238, 239, 1)" }}></Divider>
                <TableContainer
                    sx={{
                        maxHeight: "432px", // Limit the height of the table
                        overflowY: "auto", // Enable scrolling when content exceeds maxHeight
                    }}
                >
                    {currVacancyIdx < negotiations.length && negotiations[currVacancyIdx].stage_activity && negotiations[currVacancyIdx].stage_activity.length !== 0 ? (
                        <Table>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        borderBottom: "1px solid rgba(241, 242, 243, 1)", // Border bottom styling
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            color: "rgba(162, 161, 168, 1)",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            padding: "24px 0 7px 0", // Add padding for consistent spacing
                                            width: "60%",
                                            maxWidth: "60%",
                                            minHeight: "34px", // Ensure minimum height is applied via cells
                                        }}
                                    >
                                        Статус
                                    </TableCell>
                                    {/* Empty column for spacing */}
                                    <TableCell
                                        sx={{
                                            width: "8px",
                                            minHeight: "34px", // Ensure minimum height is applied via cells
                                            backgroundColor: "transparent", // Ensures it's invisible
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            color: "rgba(162, 161, 168, 1)",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            padding: "15px 0 7px 0", // Add padding for consistent spacing
                                            minHeight: "34px", // Ensure minimum height is applied via cells
                                        }}
                                    >
                                        Время
                                    </TableCell>
                                </TableRow>
                                {negotiations[currVacancyIdx].stage_activity.map((stage, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            borderBottom: "1px solid rgba(241, 242, 243, 1)", // Border bottom styling
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                color: index === 0 ? "rgba(22, 21, 28, 1)" : "rgba(162, 161, 168, 1)",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                padding: "15px 0 7px 0", // Add padding for column spacing
                                                width: "60%",
                                                maxWidth: "60%",
                                                minHeight: "34px", // Ensure minimum height is applied via cells
                                            }}
                                        >
                                            {stage.stage_name}
                                        </TableCell>
                                        {/* Empty column for spacing */}
                                        <TableCell
                                            sx={{
                                                width: "8px",
                                                minHeight: "34px", // Ensure minimum height is applied via cells
                                                backgroundColor: "transparent", // Ensures it's invisible
                                            }}
                                        />
                                        <TableCell
                                            sx={{
                                                color: index === 0 ? "rgba(22, 21, 28, 1)" : "rgba(162, 161, 168, 1)",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                padding: "15px 0 7px 0", // Add padding for column spacing
                                                minHeight: "34px", // Ensure minimum height is applied via cells
                                            }}
                                        >
                                            {getTimeStampFomatted(stage.changed_at)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                mt: 2, // Adds margin-top of 2
                                height: "100%", // Ensure it takes full height of the parent
                            }}
                        >
                            <Typography component="div">Пустая История Этапов</Typography>
                        </Box>)
                    }
                </TableContainer>
            </Box>
        </>
    )
})

export default NegotiationActivityUi;