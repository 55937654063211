import React from "react";
import {
    Box,
    Typography,
    TextField,
    Button,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ThirdStep = ({
    handleBack,
    handleCreate,
    name,
    salaryFrom,
    salaryTo,
    currency,
    experience,
    employmentType,
    workSchedule,
    taxType,
    text,
    city,
    department,
    accept_temporary,
    stages,
}) => {

    const returnExperienceText = (key) => {
        switch (key) {
            case 'noExperience':
                return 'Нет опыта'
            case 'between1And3':
                return 'От 1 года до 3 лет'
            case 'between3And6':
                return 'От 3 до 6 лет'
            case 'moreThan6':
                return 'Более 6 лет'
            default:
                return 'Нет опыта'
        }
    }

    const returnEmploymentTypeText = (key) => {
        switch (key) {
            case 'full':
                return 'Полная занятость'
            case 'part':
                return 'Частичная занятость'
            case 'project':
                return 'Проектная работа'
            case 'volunteer':
                return 'Волонтерство'
            case 'probation':
                return 'Стажировка'
            default:
                return 'Полная занятость'
        }
    }

    const returnScheduleText = (key) => {
        switch (key) {
            case 'fullDay':
                return 'Полный день'
            case 'shift':
                return 'Сменный график'
            case 'flexible':
                return 'Гибкий график'
            case 'remote':
                return 'Удаленная работа'
            case 'flyInFlyOut':
                return 'Вахтовый метод'
            default:
                return 'Полный день'
        }
    }

    return (
        <>
            {/* Заголовок Шага 3 */}
            {/* Accordion для "Посмотреть вакансию" */}
            <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: '#E8EFE4',
                        minHeight: '56px', // Фиксируем минимальную высоту
                        height: '56px', // Фиксируем общую высоту
                        boxShadow: 'none', // Убираем тень
                        '&.Mui-expanded': {
                            minHeight: '56px', // Фиксируем высоту при открытии
                            height: '56px',
                        },
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: 600, color: '#599D15', fontSize: '18px' }}>
                        Посмотреть вакансию
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: '1fr 1fr 1fr', mb: 3 }}>
                        <TextField label="Название" value={name} InputProps={{ readOnly: true }} />
                        <TextField label="Город" value={city ? city.name : null} InputProps={{ readOnly: true }} />
                        <TextField label="Отдел" value={department ? department.name : null} InputProps={{ readOnly: true }} />
                        <TextField label="Заработная плата от" value={salaryTo} InputProps={{ readOnly: true }} />
                        <TextField label="Заработная плата до" value={salaryFrom} InputProps={{ readOnly: true }} />
                        <TextField label="" value={taxType ? "До вычета налогов" : "На руки"} InputProps={{ readOnly: true }} />
                        <TextField label="Валюта" value={currency} InputProps={{ readOnly: true }} />
                        <TextField label="Опыт работы" value={returnExperienceText(experience)} InputProps={{ readOnly: true }} />
                        <TextField label="Тип занятости" value={returnEmploymentTypeText(employmentType)} InputProps={{ readOnly: true }} />
                        <TextField label="График работы" value={returnScheduleText(workSchedule)} InputProps={{ readOnly: true }} />
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                                checked={accept_temporary}
                                sx={{
                                    color: "#599D15",
                                    padding: 0,
                                    mr: 1,
                                    "&.Mui-checked": {
                                        color: "#599D15",
                                    },
                                }}
                            />
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    color: "#151813",
                                }}
                            >
                                Возможно временное оформление
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Typography sx={{ mt: 2, fontWeight: 500 }}>Обязанности</Typography>
                    <Paper
                        elevation={0}
                        sx={{
                            mt: 2,
                            p: 2,
                            borderRadius: '4px',
                            border: '1px solid #D2D6DA',
                            backgroundColor: '#FFFFFF',
                            width: '500px',
                        }}
                    >
                        <Box>
                            {/* HTML-содержимое description */}
                            <Typography
                                sx={{ fontSize: '16px', lineHeight: '20px', color: '#151813', padding: 0 }}
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        </Box>
                    </Paper>
                </AccordionDetails>
            </Accordion>

            {/* Accordion для "Посмотреть этапы" */}
            <Accordion defaultExpanded sx={{ mt: 3, boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: '#E8EFE4',
                        minHeight: '56px', // Фиксируем минимальную высоту
                        height: '56px', // Фиксируем общую высоту
                        boxShadow: 'none', // Убираем тень
                        '&.Mui-expanded': {
                            minHeight: '56px', // Фиксируем высоту при открытии
                            height: '56px',
                        },
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: 600, color: '#599D15', fontSize: '18px' }}>
                        Посмотреть этапы
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #E0E0E0',
                            py: 1,
                        }}
                    >
                        <Typography>Новый</Typography>
                    </Box>
                    {stages && stages.map((stage, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom: '1px solid #E0E0E0',
                                py: 1,
                            }}
                        >
                            <Typography>{stage.name}</Typography>
                        </Box>
                    ))}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #E0E0E0',
                            py: 1,
                        }}
                    >
                        <Typography>Принял оффер</Typography>
                    </Box>
                </AccordionDetails>
            </Accordion>


            {/* Кнопки "Назад" и "Создать" */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
                <Button
                    variant="outlined"
                    sx={{ marginRight: 2, height: '56px', boxShadow: 'none', borderRadius: '8px', width: '202px', color: '#599D15', borderColor: '#599D15', fontSize: '16px', fontWeight: '400' }}
                    onClick={handleBack}
                >
                    Назад
                </Button>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: '#599D15', color: 'white', height: '56px', boxShadow: 'none', borderRadius: '8px', width: '202px', fontSize: '16px', fontWeight: '400' }}
                    onClick={handleCreate} // Здесь обработчик для создания вакансии
                >
                    Создать
                </Button>
            </Box>
        </>
    )
}


export default ThirdStep;