import React from 'react';
import { Box, Typography, Divider, Paper } from '@mui/material';

// Функция для форматирования зарплаты
function formatCurrency(value) {
    if (!value) return ''; // Если значение пустое, возвращаем пустую строку
    return `${Number(value).toLocaleString('ru-RU')} тг`;
}

const DescriptionTab = ({ vacancy }) => {
    return (
        <Box mt={3}>
            <Typography
                sx={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 400,
                    color: '#A2A1A8',
                }}
            >
                Зарплата
            </Typography>
            {vacancy.salary_from === vacancy.salary_to && (
                <Typography
                    sx={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 400,
                        color: '#16151C',
                        marginTop: '5px'
                    }}
                >
                    {vacancy.salary_from}
                </Typography>
            )}
            {vacancy.salary_from || vacancy.salary_to && (
                <Typography
                    sx={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 400,
                        color: '#16151C',
                        marginTop: '5px'
                    }}
                >
                    {vacancy.salary_from ? `${formatCurrency(vacancy.salary_from)} - ` : 'до'} {formatCurrency(vacancy.salary_to)}
                </Typography>
            )}
            {!vacancy.salary_from && !vacancy.salary_to && (
                <Typography
                    sx={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 400,
                        color: '#16151C',
                        marginTop: '5px'
                    }}
                >
                    Не указано
                </Typography>
            )}

            <Divider sx={{ mt: 3, width: '500px', marginTop: '10px' }} />

            <Typography
                sx={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 400,
                    color: '#A2A1A8',
                    marginTop: '24px'
                }}
            >
                Описание
            </Typography>
            <Paper
                elevation={0}
                sx={{
                    mt: 2,
                    p: 2,
                    borderRadius: '4px',
                    border: '1px solid #D2D6DA',
                    backgroundColor: '#FFFFFF',
                    width: '500px',
                }}
            >
                <Box>
                    {/* HTML-содержимое description */}
                    <Typography
                        sx={{ fontSize: '16px', lineHeight: '20px', color: '#151813', padding: 0 }}
                        dangerouslySetInnerHTML={{ __html: vacancy.description }}
                    />
                </Box>
            </Paper>
        </Box>
    );
};

export default DescriptionTab;
