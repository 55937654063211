import React from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Paper, Checkbox } from '@mui/material';
import VacancyTableRow from './VacancyTableRow';

const VacancyTable = ({ vacancies }) => {
    return (
        <TableContainer
            component={Paper}
            sx={{ boxShadow: 'none', borderRadius: 0, backgroundColor: '#FFFFFF' }}
        >
            <Table sx={{ minWidth: 650 }} aria-label="vacancy table">
                <TableHead>
                    <TableRow>
                        {/* <TableCell padding="checkbox">
                            <Checkbox sx={{ color: '#6E6E6E' }} />
                        </TableCell> */}
                        <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E' }}>Название вакансии</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E' }}>Регион</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E' }}>Отдел</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E' }}>Отклики</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E' }}>Рекрутер</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E' }}>Дата создания</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vacancies.map((vacancy) => (
                        <VacancyTableRow
                            key={vacancy.id}
                            id={vacancy.id}
                            position={vacancy.name}
                            region={vacancy.city_name}
                            department={vacancy.department_name}
                            responses={vacancy.responses || 0} // предполагая, что поле responses доступно
                            recruiter={vacancy.recruiter_name}
                            date={new Date(vacancy.created_at).toLocaleDateString()}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default VacancyTable;
