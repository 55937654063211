import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';

const PopUpWindow = ({ anchorEl, open, onClose, onClick,  stages, children, sx }) => {
    
    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            sx={sx}
        >
            <MenuItem
                sx={{
                    pointerEvents: "none", // Убирайем поинтер
                    backgroundColor: "transparent !important", // Убираем подсветку
                    "&:hover": {
                        backgroundColor: "transparent !important", // Убираем подсветку при наведении
                    },
                }}>
                <Typography sx={{ fontSize: "14px", color: "#599D15", fontWeight: "bold", backgroundColor: "transparent !important" }}>
                    Сменить этап подбора
                </Typography>
            </MenuItem>
            {stages.map((stage) => (
                <MenuItem
                    key={stage.id}
                    onClick={() => onClick(stage.id)}
                >
                    {stage.name}
                </MenuItem>
            ))}
            {children}
        </Menu>
    )
}

export default PopUpWindow;