import React, { useState } from 'react';
import { TableRow, TableCell, IconButton, Menu, MenuItem } from '@mui/material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

const VacancyTableRow = ({ id, position, region, department, responses, recruiter, date }) => {
    const navigate = useNavigate();

    // Состояние для открытия меню
    const [anchorEl, setAnchorEl] = useState(null);

    const handleRowClick = () => {
        navigate(`/vacancy/${id}`);
    };

    const handleMenuOpen = (event) => {
        event.stopPropagation(); // Останавливаем всплытие клика
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (event) => {
        event.stopPropagation(); // Останавливаем всплытие клика
        setAnchorEl(null);
    };

    const handleEditClick = (event) => {
        event.stopPropagation(); // Останавливаем всплытие клика
        handleMenuClose(event); // Закрываем меню
        navigate(`/vacancy/edit/${id}`); // Переход на страницу редактирования
    };

    return (
        <TableRow hover onClick={handleRowClick} sx={{ cursor: 'pointer' }}>
            <TableCell>
                <WorkOutlineIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                {position}
            </TableCell>
            <TableCell>{region}</TableCell>
            <TableCell>{department}</TableCell>
            <TableCell>{responses}</TableCell>
            <TableCell>{recruiter}</TableCell>
            <TableCell>{date}</TableCell>
            <TableCell align="right">
                <IconButton size="small" onClick={handleMenuOpen}>
                    <MoreVertIcon />
                </IconButton>
                {/* Меню с действиями */}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={handleEditClick}>Редактировать</MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
};

export default VacancyTableRow;
