import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const isAuthenticated = Boolean(localStorage.getItem('access_token'));
    const privacyAccepted = localStorage.getItem('privacy_accepted') === 'true';
    const hasManagerId = Boolean(localStorage.getItem('manager_id'));

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    if (!privacyAccepted && window.location.pathname !== '/new_password') {
        return <Navigate to="/new_password" replace />;
    }

    if (privacyAccepted && !hasManagerId && window.location.pathname !== '/select_account') {
        return <Navigate to="/select_account" replace />;
    }

    return <Component {...rest} />;
};

export default ProtectedRoute;
