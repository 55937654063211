import React from 'react';
import { Box, Typography, Chip, Divider } from '@mui/material';
import { formatDate } from '../../utils/formatedDate';

export const ExperienceUi = ({ sx = {}, experience = [], render = {isDivider : true} }) => {
    if (!experience) {
        experience = [];
    }

    return (
        <Box sx={sx}>
            <Typography variant="subtitle1" sx={{ fontWeight: 400, mb: 2, color: '#ADB5BD', fontSize: '14px' }}>
                Опыт работы
            </Typography>
            <Box sx={{ mb: 2 }}>
                {experience.length !== 0 ? experience.map((el, index) => (
                    <Box key={index} sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400, mr: 2 }} variant="body1">{formatDate(el.start, "MM.YYYY")} - {el.end ? formatDate(el.end, "MM.YYYY") : "Настоящее время"}</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>{el.company}</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                {el.position}
                            </Typography>
                        </Box>
                    </Box>
                )) : <EmptyData/>}
            </Box>
            { render.isDivider && <Divider sx={{ marginTop: "16px", backgroundColor: "rgba(235, 238, 239, 1)" }}></Divider>}
        </Box>
    );
}

export const KeySkillsUi = ({ sx = {}, skills = [], render = {isDivider : true} }) => {
    if (!skills) {
        skills = [];
    }

    return (
        <Box sx={sx}>
            <Typography variant="subtitle1" sx={{ fontWeight: 400, mb: 2, color: '#ADB5BD', fontSize: '14px' }}>
                Ключевые навыки
            </Typography>
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 2 }}>
                {skills.length !== 0 ? skills.map((skill, index) => (
                    <Chip key={index} label={skill} />
                )) : <Typography sx={{ color: '#151813', fontSize: '16px', fontWeight: 400 }}>Не указано</Typography>}
            </Box>
            { render.isDivider && <Divider sx={{ marginTop: "16px", backgroundColor: "rgba(235, 238, 239, 1)" }}></Divider>}
        </Box>
    );
}

export const EducationUi = ({ sx = {}, education = [], render = {isDivider : true} }) => {
    if (!education) {
        education = [];
    }

    return (
        <Box sx={sx}>
            <Typography variant="subtitle1" sx={{ fontWeight: 400, mb: 2, color: '#ADB5BD', fontSize: '14px' }}>
                Образование
            </Typography>
            <Box sx={{ mb: 2 }}>
                {education.length !== 0 ? education.map((el) => (
                    <Box key = {el.id} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400 }} variant="body1">{el.year}</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>{el.name}</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                {el.result}
                            </Typography>
                        </Box>
                    </Box>
                )) : <Typography sx={{ color: '#151813', fontSize: '16px', fontWeight: 400 }}>Не указано</Typography>}
            </Box>
            { render.isDivider && <Divider sx={{ marginTop: "16px", backgroundColor: "rgba(235, 238, 239, 1)" }}></Divider>}
        </Box>
    );
}

const EmptyData = () => {
    return (
        <Typography sx={{ color: '#151813', fontSize: '16px', fontWeight: 400 }}>Не указано</Typography>
    )
}

export const FilesUi = ({ sx = {}, file, onClick, render = {isDivider : true}}) => {
    if (!file) {
        return (null);
    }

    return (
        <Box sx={sx}>
            <Typography variant="subtitle1" sx={{ fontWeight: 400, mb: 2, color: '#ADB5BD', fontSize: '14px' }}>
                Файлы
            </Typography>
            <Box sx={{ mb: 2 }}>
                <Typography onClick={onClick} sx={{ flex: 1, color: '#1F6BC5', fontSize: '16px', fontWeight: 400, cursor: 'pointer', textDecoration: 'underline' }} variant="body1">Прикрепленный файл</Typography>
                {/* {education.map((el) => (
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400 }} variant="body1">{el.year}</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>{el.name}</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                {el.result}
                            </Typography>
                        </Box>
                    </Box>
                ))} */}
            </Box>
            { render.isDivider && <Divider sx={{ marginTop: "16px", backgroundColor: "rgba(235, 238, 239, 1)" }}></Divider>}
        </Box>
    );
}

export const PreferencesUi = ({ sx = {}, preferences = {}, render = {isMainDivider : true, isSubDividers : true} }) => {
    if (!preferences) {
        preferences = {};
    }

    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '16px', ...sx }}>
                <Box sx={{ width: 'calc(50% - 8px)' }}>
                    <Typography sx={{ fontWeight: 400, mb: '5px', color: '#ADB5BD', fontSize: '14px' }} variant="body2" color="text.secondary">
                        Тип занятости
                    </Typography>
                    <Typography sx={{ fontWeight: 400, color: '#151813', fontSize: '16px' }}>
                        {preferences.employment || 'Не указано'}
                    </Typography>
                    {render.isSubDividers && <Divider sx={{ marginTop: "10px", backgroundColor: "rgba(235, 238, 239, 1)" }}></Divider>}
                </Box>
                <Box sx={{ width: 'calc(50% - 8px)' }}>
                    <Typography sx={{ fontWeight: 400, mb: '5px', color: '#ADB5BD', fontSize: '14px' }} variant="body2" color="text.secondary">
                        График работы
                    </Typography>
                    <Typography sx={{ fontWeight: 400, mb: '5px', color: '#151813', fontSize: '16px' }}>
                        {preferences.schedule || 'Не указано'}
                    </Typography>
                    {render.isSubDividers && <Divider sx={{ marginTop: "10px", backgroundColor: "rgba(235, 238, 239, 1)" }}></Divider>}
                </Box>
                <Box sx={{ width: 'calc(50% - 8px)' }}>
                    <Typography sx={{ fontWeight: 400, mb: '5px', color: '#ADB5BD', fontSize: '14px' }} variant="body2" color="text.secondary">
                        Командировки
                    </Typography>
                    <Typography sx={{ fontWeight: 400, mb: '5px', color: '#151813', fontSize: '16px' }}>
                        {preferences.business_trip || 'Не указано'}
                    </Typography>
                    {render.isSubDividers && <Divider sx={{ marginTop: "10px", backgroundColor: "rgba(235, 238, 239, 1)" }}></Divider>}
                </Box>
            </Box>
            {render.isMainDivider && <Divider sx={{ marginTop: "16px", backgroundColor: "rgba(235, 238, 239, 1)" }}></Divider>}
        </>
    );
}

export const InfoFieldUi = ({ sx = {}, title, text, children, render = {isDivider : true} }) => {
    return (
        <Box sx={{display: "flex", flexDirection : "column", borderBottom: render.isDivider ? "1px solid rgba(235, 238, 239, 1)" : "none", ...sx}}>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "rgba(173, 181, 189, 1)",
                }}
            >
                {title}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "rgba(21, 24, 19, 1)",
                    }}
                >
                    {text}
                </Typography>
                {children}
            </Box>
        </Box>
    )
}

{/* Повышение квалификации */ }
{/* <Typography variant="subtitle1" sx={{ fontWeight: 400, mb: 2, color: '#ADB5BD', fontSize: '14px' }}>
                    Повышение квалификации, курсы
                </Typography>
                <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400 }} variant="body1">2020</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>Changzhou Institute of Technology</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                Bachelor of economics, - BE, Sales
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400 }} variant="body1">2017</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>NIS</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                Astana
                            </Typography>
                        </Box>
                    </Box>
                </Box> */}

{/* <Divider sx={{ mt: 2, mb: 2 }} /> */ }

{/* Тесты, экзамены
                <Typography variant="subtitle1" sx={{ fontWeight: 400, mb: 2, color: '#ADB5BD', fontSize: '14px' }}>
                    Тесты, экзамены
                </Typography>
                <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400 }} variant="body1">2020</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>Changzhou Institute of Technology</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                Bachelor of economics, - BE, Sales
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400 }} variant="body1">2017</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>NIS</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                Astana
                            </Typography>
                        </Box>
                    </Box>
                </Box> */}