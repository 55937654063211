import React, { useState } from "react";
import {
    Box,
    Typography,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    MenuItem,
    Divider,
    CircularProgress,
    Checkbox
} from '@mui/material';
import LabeledInput from "../main/LabeledInput";
import VacancyEditor from '../../components/vacancy/VacancyEditor';
import Autocomplete from '@mui/material/Autocomplete';
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { fetchCities, fetchDepartments, fetchCurrencies } from "../../services/dictionariesApi";
import { useNavigate } from "react-router-dom";

const FirstStep = ({
    name,
    setName,
    salaryFrom,
    setSalaryFrom,
    salaryTo,
    setSalaryTo,
    currency,
    setCurrency,
    experience,
    setExperience,
    employmentType,
    setEmploymentType,
    workSchedule,
    setWorkSchedule,
    taxType,
    setTaxType,
    text,
    setText,
    city,
    setCity,
    department,
    setDepartment,
    accept_temporary,
    set_accept_temporary
}) => {
    const navigate = useNavigate()
    const [citySearchQuery, setCitySearchQuery] = useState("");
    const [departmentSearchQuery, setDepartmentSearchQuery] = useState("");

    // Запрос на города
    const {
        data: cityPages,
        fetchNextPage: fetchNextCityPage,
        hasNextPage: hasNextCityPage,
        isFetchingNextPage: isFetchingNextCityPage,
        isLoading: isCityLoading,
    } = useInfiniteQuery(
        ["cities", citySearchQuery],
        ({ pageParam = 1 }) => fetchCities(citySearchQuery, pageParam, 10),
        {
            getNextPageParam: (lastPage) => {
                const currentPage = parseInt(lastPage.current_page, 10);
                const totalPages = Math.ceil(lastPage.total / lastPage.per_page);
                return currentPage < totalPages ? currentPage + 1 : undefined;
            },
        }
    );

    const cityOptions = cityPages?.pages.flatMap((page) => page.data) || [];

    // Запрос на отделы
    const {
        data: departmentPages,
        fetchNextPage: fetchNextDepartmentPage,
        hasNextPage: hasNextDepartmentPage,
        isFetchingNextPage: isFetchingNextDepartmentPage,
        isLoading: isDepartmentLoading,
    } = useInfiniteQuery(
        ["departments", departmentSearchQuery],
        ({ pageParam = 1 }) => fetchDepartments(departmentSearchQuery, pageParam, 10),
        {
            getNextPageParam: (lastPage) => {
                const currentPage = parseInt(lastPage.current_page, 10);
                const totalPages = Math.ceil(lastPage.total / lastPage.per_page);
                return currentPage < totalPages ? currentPage + 1 : undefined;
            },
        }
    );

    const departmentOptions = departmentPages?.pages.flatMap((page) => page) || [];

    const {
        data: currencies = [],
        isLoading: isCurrencyLoading,
    } = useQuery(["currencies"], fetchCurrencies);

    const handleCityScrollEnd = (event) => {
        const listboxNode = event.currentTarget;
        const bottom =
            listboxNode.scrollHeight - listboxNode.scrollTop <=
            listboxNode.clientHeight + 10;
        if (bottom && hasNextCityPage && !isFetchingNextCityPage) {
            fetchNextCityPage();
        }
    };

    const handleDepartmentScrollEnd = (event) => {
        const listboxNode = event.currentTarget;
        const bottom =
            listboxNode.scrollHeight - listboxNode.scrollTop <=
            listboxNode.clientHeight + 10;
        if (bottom && hasNextDepartmentPage && !isFetchingNextDepartmentPage) {
            fetchNextDepartmentPage();
        }
    };






    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" component="h1" sx={{ fontWeight: 500, fontSize: 20, lineHeight: '24px' }}>
                    Основная информация
                </Typography>
                <Button onClick={() => navigate('/')} variant="outlined" sx={{ color: '#FF3B30', borderColor: '#FFA8A6', height: '44px', borderRadius: '8px' }}>
                    Отменить
                </Button>
            </Box>
            <Divider sx={{ mt: 2, mb: 4 }} />

            <Box sx={{ display: 'flex', gap: 2 }}>
                <LabeledInput
                    label="Название"
                    value={name}
                    onChange={(event) => { setName(event.target.value) }}
                    placeholder="Введите название вакансии"
                    width="356px"
                    required
                />
                <Box sx={{ mb: 3, width: '356px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '20px', color: '#151813', mb: 1 }}>
                        Город <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Autocomplete
                        noOptionsText={"Нет вариантов"}
                        value={city}
                        onChange={(event, newValue) => {
                            setCity(newValue);
                            console.log("Selected City: ", newValue);
                        }}
                        onInputChange={(event, value) => setCitySearchQuery(value)}
                        options={cityOptions}
                        getOptionLabel={(option) => option?.name || "Без названия"}
                        filterOptions={(options) => options}
                        loading={isCityLoading || isFetchingNextCityPage}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Выберите город"
                                variant="outlined"
                                sx={{
                                    backgroundColor: '#F8F9F9',
                                    width: '100%',
                                    borderRadius: '8px',
                                    '& .MuiOutlinedInput-root': {
                                        height: '56px',
                                        borderRadius: '8px',
                                        '& fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#BDBDBD',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#599D15',
                                        },
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {(isCityLoading || isFetchingNextCityPage) && (
                                                <CircularProgress color="inherit" size={20} />
                                            )}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        ListboxProps={{
                            onScroll: handleCityScrollEnd,
                        }}
                    />
                </Box>

                <Box sx={{ mb: 3, width: "356px" }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '20px', color: '#151813', mb: 1 }}>
                        Отдел <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Autocomplete
                        noOptionsText={"Нет вариантов"}
                        value={department}
                        onChange={(event, newValue) => setDepartment(newValue)}
                        onInputChange={(event, value) => setDepartmentSearchQuery(value)}
                        options={departmentOptions}
                        getOptionLabel={(option) => option?.name || "Без названия"}
                        filterOptions={(options) => options}
                        loading={isDepartmentLoading || isFetchingNextDepartmentPage}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Выберите отдел"
                                variant="outlined"
                                sx={{
                                    backgroundColor: '#F8F9F9',
                                    width: '100%',
                                    borderRadius: '8px',
                                    '& .MuiOutlinedInput-root': {
                                        height: '56px',
                                        borderRadius: '8px',
                                        '& fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#BDBDBD',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#599D15',
                                        },
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {(isDepartmentLoading || isFetchingNextDepartmentPage) && (
                                                <CircularProgress color="inherit" size={20} />
                                            )}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        ListboxProps={{
                            onScroll: handleDepartmentScrollEnd,
                        }}
                    />
                </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, mt: 2, height: '84px' }}>
                <LabeledInput
                    label="Заработная плата от"
                    value={salaryFrom}
                    onChange={(e) => setSalaryFrom(e.target.value)}
                    placeholder="от"
                    width="356px"
                />
                <LabeledInput
                    label="до"
                    value={salaryTo}
                    onChange={(e) => setSalaryTo(e.target.value)}
                    placeholder="до"
                    width="356px"
                />
                <Box sx={{ width: '94px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '20px', color: '#151813', mb: 1 }}>
                        Валюта
                    </Typography>
                    <TextField
                        select
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                        variant="outlined"
                        sx={{
                            backgroundColor: '#F8F9F9',
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                                height: '52px',
                                borderRadius: '8px',
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#BDBDBD',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#599D15',
                                },
                            },
                        }}
                    >
                        {isCurrencyLoading ? (
                            <MenuItem>Загрузка...</MenuItem>
                        ) : (
                            currencies.map((cur) => (
                                <MenuItem key={cur.code} value={cur.code}>
                                    {cur.name}
                                </MenuItem>
                            ))
                        )}
                    </TextField>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0 }}>
                <RadioGroup row value={taxType} onChange={(e) => setTaxType(e.target.value)}>
                    <FormControlLabel
                        value={true}
                        control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />}
                        label="До вычета налогов"
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio sx={{ color: '#A2A1A8', '&.Mui-checked': { color: '#599D15' } }} />}
                        label="На руки"
                    />
                </RadioGroup>
            </Box>

            <div style={{ marginTop: '36px' }}>
                <Typography sx={{ mt: 3 }}>Описание</Typography>
                <p style={{ color: '#ADB5BD', fontSize: '14px', lineHeight: '18px', fontWeight: 400, marginTop: '8px' }}>Описание вакансии не может быть менее 150 символов</p>
                <VacancyEditor text={text} setText={setText} />
                <Typography sx={{
                    mt: 3,
                    color: text.trim().length >= 200 ? '#599D15' : 'red'
                }}>Минимальное кол-во символов 200: {text.trim().length}</Typography>
            </div>


            <Typography sx={{ mt: 3 }}>Опыт работы *</Typography>
            <RadioGroup value={experience} onChange={(e) => setExperience(e.target.value)} sx={{ mt: 1 }}>
                <FormControlLabel value="noExperience" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Нет опыта" />
                <FormControlLabel value="between1And3" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="От 1 года до 3 лет" />
                <FormControlLabel value="between3And6" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="От 3 до 6 лет" />
                <FormControlLabel value="moreThan6" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Более 6 лет" />
            </RadioGroup>

            <Typography sx={{ mt: 3 }}>Тип занятости *</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                        checked={accept_temporary}
                        onChange={(e) => set_accept_temporary(e.target.checked)}
                        sx={{
                            color: "#599D15",
                            padding: 0,
                            mr: 1,
                            "&.Mui-checked": {
                                color: "#599D15",
                            },
                        }}
                    />
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            color: "#151813",
                        }}
                    >
                        Возможно временное оформление
                    </Typography>
                </Box>
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        color: "#9BA2A9",
                        ml: "32px", // Отступ для выравнивания текста
                        mt: "-4px",
                        mt: 1 // Подгоняем высоту
                    }}
                >
                    По совместительству или по ГПХ: услуги, подряд, ИП, самозанятые
                </Typography>
            </Box>
            <RadioGroup value={employmentType} onChange={(e) => setEmploymentType(e.target.value)} sx={{ mt: 3 }}>
                <FormControlLabel value="full" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Полная занятость" />
                <FormControlLabel value="part" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Частичная занятость" />
                <FormControlLabel value="project" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Проектная работа" />
                <FormControlLabel value="volunteer" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Волонтерство" />
                <FormControlLabel value="probation" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Стажировка" />
            </RadioGroup>

            <Typography sx={{ mt: 3 }}>График работы *</Typography>
            <RadioGroup value={workSchedule} onChange={(e) => setWorkSchedule(e.target.value)} sx={{ mt: 1 }}>
                <FormControlLabel value="fullDay" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Полный день" />
                <FormControlLabel value="shift" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Сменный график" />
                <FormControlLabel value="flexible" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Гибкий график" />
                <FormControlLabel value="remote" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Удаленная работа" />
                <FormControlLabel value="flyInFlyOut" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Вахтовый метод" />
            </RadioGroup>
        </>
    );
};

export default FirstStep;
