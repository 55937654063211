import React, { memo, useState } from 'react';
import { Divider, Typography, Box, Tab, Tabs } from '@mui/material';
import { EducationUi, ExperienceUi, FilesUi, KeySkillsUi, PreferencesUi } from './candidateInfoUi';
import { getSourceName } from '../../utils/manageSources';

const ResumeFromSource = ({ sx, resume, render = {isFiles : true} }) => {
    return (
        <Box sx={sx}>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "rgba(21, 24, 19, 1)",
                }}
            >
                Дополнительная информация
            </Typography>
            <Divider sx={{ marginTop: "16px", left: "24px", right: "24px", backgroundColor: "rgba(235, 238, 239, 1)" }}></Divider>
            <ExperienceUi sx={{ marginTop: "16px" }} experience={resume?.source_body?.experience} />
            <KeySkillsUi sx={{ marginTop: "16px" }} skills={resume?.source_body?.skill_set} />
            <EducationUi sx={{ marginTop: "16px" }} education={resume?.source_body?.education?.primary} render={{isDivider: false}} />
            <PreferencesUi sx={{ marginTop: "16px" }} preferences={resume?.source_body?.preferences} render = {{isSubDividers : true}}/>
            {render.isFiles && <FilesUi onClick={() => window.open(resume?.cv_path, '_blank')} sx={{ marginTop: "16px" }} file={resume?.cv_path} render ={{isDivider : false}} />}
        </Box>
    );
}

/**
 * @param {Object} sx - styles for the resume block, if you want to prevent rerender pass sx inside useRef
 * @param {Object} resumes - resume data
 * @returns {JSX.Element} - resume block
 */
const ResumeUi = memo(({ sx, resumes }) => {
    console.log("resume being render");
    const [currentTab, setCurrentTab] = useState(0);
    const sourceCounter = {};

    const handleTabChange = (event, value) => {
        setCurrentTab(value);
    }

    if (!resumes || !resumes.length) {
        return (
            <Box sx={{ ...sx, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography
                    sx={{
                        color: "rgba(121, 128, 136, 1)",
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                    }}
                >
                    Нет действующих резюме для отоброжения
                </Typography>
            </Box>
        )
    }

    return (
        <Box sx={sx}>
            <Box>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    sx={{
                        padding: "0 24px",
                        gap: "32px",
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'rgba(46, 64, 82, 1)',
                            height: '3px',
                            borderTopLeftRadius: '2px',
                            borderTopRightRadius: '2px',
                        },
                    }}
                    TabIndicatorProps={{
                        style: { transform: 'translateY(0px)' },
                    }}
                >
                    {resumes.map((source, index) => {
                        const text = getSourceName(sourceCounter, source.source_id);
                        return (
                            <Tab
                                key={index}
                                label={
                                    <Typography
                                        sx={{
                                            color: currentTab === index ? 'rgba(46, 64, 82, 1)' : 'rgba(121, 128, 136, 1)',
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {text}
                                    </Typography>
                                }
                                sx={{
                                    textTransform: 'none',
                                    minWidth: 100,
                                    minHeight: 41,
                                    padding: '9px 8px',
                                }}
                            />
                        )
                    })}
                </Tabs>
                <Divider sx={{ backgroundColor: "rgba(226, 228, 231, 1)", margin: "0 24px" }}></Divider>
            </Box>
            <ResumeFromSource sx={{ padding: "24px" }} resume={resumes[currentTab]} />
        </Box>
    );
});

export default ResumeUi;