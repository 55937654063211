import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import VacanciesPage from './pages/vacancy/VacanciesPage';
import VacancyDetail from './pages/vacancy/VacancyDetail';
import SignInPage from './pages/auth/SignInPage';
import NewPasswordPage from './pages/auth/NewPasswordPage';
import NotFoundPage from './pages/NotFoundPage';
import AccountSelectionPage from './pages/auth/AccountSelectionPage';
import CreateVacancyPage from './pages/vacancy/CreateVacancyPage';
import NavigationBar from './components/main/NavigationBar';
import Header from './components/main/Header';
import ProtectedRoute from './components/main/ProtectedRoute';
import CandidatesPage from './pages/candidates/CandidatesPage';
import IntegrationsPage from './pages/integrations/IntegrationsPage';
import CreateCandidatesPage from './pages/candidates/CreateCandidatesPage';
import EditVacancyPage from './pages/vacancy/EditVacancyPage';

function PublicRoute({ element: Component, ...rest }) {
  const isAuthenticated = Boolean(localStorage.getItem('access_token'));
  return isAuthenticated ? <Navigate to="/" replace /> : <Component {...rest} />;
}

function App() {
  const location = useLocation();

  // Определение страниц, где не отображаются Header и NavigationBar
  const isAuthPage = ['/login', '/new_password', '/select_account'].includes(location.pathname);
  const isNotFoundPage = !location.pathname.match(
    /^\/(vacancy\/\d+|vacancy\/create|vacancy\/edit\/\d+|candidates|candidates\/create|login|new_password|select_account|settings\/integrations)?$/
  );

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* NavigationBar отображается везде, кроме страниц авторизации и ошибок */}
      {!isAuthPage && !isNotFoundPage && <NavigationBar />}

      <Box sx={{ flex: 1, overflowY: 'auto', background: "#f8f8f8" }}>
        {/* Header отображается везде, кроме страниц авторизации и ошибок */}
        {!isAuthPage && !isNotFoundPage && <Header />}

        <Routes>
          {/* Публичные маршруты */}
          <Route path="/login" element={<PublicRoute element={SignInPage} />} />
          <Route path="/new_password" element={<ProtectedRoute element={NewPasswordPage} />} />

          {/* Защищенные маршруты */}
          <Route path="/select_account" element={<ProtectedRoute element={AccountSelectionPage} />} />
          <Route path="/" element={<ProtectedRoute element={VacanciesPage} />} />
          <Route path="/vacancy/:id" element={<ProtectedRoute element={VacancyDetail} />} />
          <Route path="/vacancy/create" element={<ProtectedRoute element={CreateVacancyPage} />} />
          <Route path="/vacancy/edit/:id" element={<ProtectedRoute element={EditVacancyPage} />} />
          <Route path="/candidates" element={<ProtectedRoute element={CandidatesPage} />} />
          <Route path="/candidates/create" element={<ProtectedRoute element={CreateCandidatesPage} />} />
          <Route path="/settings/integrations" element={<ProtectedRoute element={IntegrationsPage} />} />

          {/* Страница 404 */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Box>
    </Box>
  );
}


function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
