import React, { useState, memo, useRef } from 'react';
import {
    Box,
    CircularProgress,
    Avatar,
    Typography,
    Modal,
    Link,
    Divider,
} from '@mui/material';
import { CloseIcon, PlusIcon } from './svgComponents';
import { fetchCandidateById } from "../services/candidatesApi";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ResumeUi from './candidateInfo/candidateResumeUi';
import CommentSectionUi from './candidateInfo/candidateCommentsUi.jsx';
import NegotiationActivityUi from './candidateInfo/negotiationsActivityUi.jsx';
import MainInformationUi from './candidateInfo/candidateMainInfoUi.jsx';
import Negotiations from './candidateInfo/negotiations.jsx';

// Close Icon Component
const CloseButton = ({ onClose }) => (
    <CloseIcon
        onClick={onClose}
        sx={{
            width: 24,
            heigth: 24,
            position: "absolute",
            right: "24px",
            borderRadius: "2px",
            cursor: "pointer",
        }}
        svgSx={{
            width: 16,
            height: 16,
            color: "rgba(0, 0, 0, 0.5)",
            hoverColor: "rgba(0, 0, 0, 0.75)",
            pressedColor: "rgba(0, 0, 0, 1)"
        }}
    />
);

// Loading Component
const LoadingState = memo(({ onClose }) => {
    <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "24px",
        }}
    >
        <CircularProgress sx={{ display: "block", margin: "0 24px 0 0" }} />
        <CloseButton onClose={onClose} />
    </Box>
});


// Error Component
const ErrorState = ({ onClose }) => (
    <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "24px",
        }}
    >
        <Typography variant="h6" color="error" sx={{ marginRight: "24px" }}>
            Ошибка при загрузке кандидата
        </Typography>
        <CloseButton onClose={onClose} />
    </Box>
);

// Main component
const ShowModalWindow = ({ handleModalWindow, candidateId, vacancyId, handleRefusal, onStageChange, negotiationId, currStageId, stages }) => {
    const [currVacancyIdx, setCurrVacancyIdx] = useState(null);
    const queryKey = useRef(["active_candidate"]);

    // Fetch specific candidate by ID
    const { data: candidate, isLoading, isError } = useQuery(
        queryKey.current, // Query key
        async () => {
            const result = await fetchCandidateById(candidateId);
            if (result) {
                const index = result.negotiations?.findIndex(
                    (negotiation) => negotiation.id === +vacancyId
                ) ?? 0;
                setCurrVacancyIdx(index);
            }
            return result;
        },
        {
            retry: 0,
            refetchOnWindowFocus: false,
        }
    );

    console.log("Show modal window is rendered")

    return (
        <Modal open={true}>
            <Box
                onClick={(e) => e.stopPropagation()}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(248, 249, 249, 1)',
                    border: 'none !important',
                    borderRadius: "8px",
                    maxWidth: 1090,
                    maxHeight: 632,
                    overflowY: "auto",
                    overflowX: "hidden",
                    padding: "0px 0px 36px 0px",
                }}
            >
                {(currVacancyIdx === null || isLoading) ? (
                    <LoadingState onClose={handleModalWindow} />
                ) : isError ? (
                    <ErrorState onClose={handleModalWindow} />
                ) : (
                    <>
                        <TopPanel onClose={handleModalWindow} />
                        <Box sx={{ display: "flex", padding: "16px 24px 36px 24px", gap: "16px" }}>
                            <LeftContent currVacancyIdx={currVacancyIdx} setCurrVacancyIdx={setCurrVacancyIdx} queryKey={queryKey.current} />
                            <RightContent currVacancyIdx={currVacancyIdx} candidate={candidate} queryKey={queryKey.current} handleRefusal={handleRefusal} onStageChange={onStageChange} negotiationId={negotiationId} currStageId={currStageId} stages={stages} vacancyId={+vacancyId} />
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
}

const TopPanel = memo(({ onClose }) => {
    const queryClient = useQueryClient();
    const candidate = queryClient.getQueryData(["active_candidate"]);

    return (
        <Box sx={{ width: "1090px", backgroundColor: "#FFFFFF" }}>
            <Box
                sx={{
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    padding: "24px 24px 28px 24px",
                    border: "none",
                    borderRadius: "8px 8px 0 0",
                }}>
                <Avatar
                    src={candidate.avatar_small || null}
                    alt={`${candidate.first_name} ${candidate.last_name}`}
                    sx={{
                        width: 84,
                        height: 84,
                        backgroundColor: "#E0E0E0",
                        borderRadius: '10px'
                    }}
                />
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "20px",
                        lineHeight: "24px",
                        color: "black",
                        marginLeft: "16px"
                    }}
                >
                    {candidate.first_name} {candidate.last_name}
                </Typography>
                <CloseButton onClose={onClose} />
            </Box>
            <Box sx={{ padding: "0 24px 0 24px", minHeight: "32px", position: "relative" }}>
                
            </Box>
        </Box>
    );
});

const LeftContent = ({ currVacancyIdx, setCurrVacancyIdx, queryKey }) => {
    const resumeSxRef = useRef({ display: "flex", paddingTop: "24px", gap: "16px", flexDirection: "column", backgroundColor: "#FFFFFF" });
    const menuSxRef = useRef({ padding: "24px", backgroundColor: "#FFFFFF" });
    const queryClient = useQueryClient();
    const candidate = queryClient.getQueryData(queryKey);
    const resumes = candidate?.resumes ?? [];
    const negotiations = candidate?.negotiations ?? [];
    return (
        <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "586px", gap: "16px" }}>
            <Negotiations negotiations = {negotiations} currVacancyIdx={currVacancyIdx} setCurrVacancyIdx={setCurrVacancyIdx} />
            <MainInformationUi sx= {menuSxRef.current} phone={candidate?.phone} email={candidate?.email} birth_date={candidate?.birth_date} city_name={candidate?.city_name} desired_position={candidate?.desiredPosition} salary = {candidate?.salary}/>
            <ResumeUi sx={resumeSxRef.current} resumes={resumes} />
        </Box>
    );
}

const FileDownloadComponent = memo(({ currVacancyIdx }) => {
    const queryClient = useQueryClient();
    const candidate = queryClient.getQueryData(["active_candidate"]);
    const negotiations = candidate?.negotiations ?? [];
    const fileUrl = currVacancyIdx !== null && currVacancyIdx < negotiations.length ? negotiations[currVacancyIdx].cv_path : null;
    const fileName = candidate
        ? `${candidate.first_name} ${candidate.last_name} резюме.pdf`
        : "резюме.pdf";
    return (
        <Box
            sx={{
                padding: "24px",
                backgroundColor: "#fff",
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    fontWeight: "500",
                    fontSize: "20px",
                }}
            >
                Файлы
            </Typography>
            <Divider sx={{ margin: "16px 0" }} />
            {fileUrl ? (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        "&:hover": {
                            backgroundColor: "rgba(244, 247, 242, 1)",
                        },
                        "&:active": {
                            backgroundColor: "#fff",
                            color: "rgba(69, 122, 16, 1)"
                        },
                        borderRadius: "8px",
                    }}
                >
                    <PlusIcon sx={{ width: "20px", height: "20px" }} svgSx={{ width: "10px", height: "10px", color: "rgba(89, 157, 21, 1)" }} />
                    <Link
                        href={fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="hover"
                        sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "rgba(89, 157, 21, 1)",
                            textDecoration: "underline",
                        }}
                    >
                        {fileName}
                    </Link>
                </Box>)
                :
                (<Typography>Данный Отклик не Содержит Файлов</Typography>)
            }
        </Box>
    );
});

const RightContent = ({ vacancyId, currVacancyIdx, candidate, queryKey, handleRefusal, onStageChange, negotiationId, currStageId, stages }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: 440, gap: "16px" }}>
            <FileDownloadComponent currVacancyIdx={currVacancyIdx} />
            <NegotiationActivityUi vacancyId = {vacancyId} currVacancyIdx={currVacancyIdx} handleRefusal={handleRefusal} onStageChange={onStageChange} negotiationId={negotiationId} currStageId={currStageId} stages={stages} />
            <CommentSectionUi candidateId = {candidate.id} comments = {candidate?.comments} queryKey={queryKey} />
        </Box>
    );
}

export default ShowModalWindow;