import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Modal,
    Typography,
    TextField,
    FormControl,
    FormLabel,
    ListItem,
    ListItemButton,
    ListItemText,
    Collapse,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Radio,
    List,
    Snackbar,
    Alert,
} from "@mui/material";
import { useParams } from 'react-router-dom';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { fetchProfessionalRoles } from "../../services/hhpublicationApi";
import LanguageSection from "./LanguagesSection";
import DriverLicenseSection from "./DriverLicenseSection";
import SkillsSection from "./SkillsSection";
import { publishToHH } from "../../services/hhpublicationApi"; // Импортируем функцию для запроса


const PublicationModalHH = ({ isModalOpen, closeModal }) => {
    const { id: vacancyId } = useParams();
    const [skills, setSkills] = useState([]);
    const [licenseData, setLicenseData] = useState([]);
    const [languagesData, setLanguagesData] = useState([{ id: "", level: "" }]);
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [openCategories, setOpenCategories] = useState({});
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isRolesHide, setRolesHide] = useState(false);

    const [billing_type, setBillingType] = useState(null)
    const [type, setType] = useState(null)
    const [custom_employer_name, setCustomEmployerName] = useState('')
    const [body, setBody] = useState({
        only_saturday_and_sunday: false,
        from_four_to_six_hours_in_a_day: false,
        start_after_sixteen: false,
        accept_handicapped: false,
        accept_incomplete_resumes: false,
        response_letter_required: false
    })

    // Состояние для Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // Загрузка данных из API
    useEffect(() => {
        const loadCategories = async () => {
            const data = await fetchProfessionalRoles();
            const fetchedCategories = data?.data.categories || [];
            setCategories(fetchedCategories);
            setFilteredCategories(fetchedCategories);
        };
        loadCategories();
    }, []);

    // Управление раскрытием категорий
    const toggleCategory = (id) => {
        setOpenCategories((prev) => ({ ...prev, [id]: !prev[id] }));
    };


    // Обработка поиска
    const handleSearch = (e) => {
        const text = e.target.value.toLowerCase();
        setSearchText(text);

        if (!text) {
            setFilteredCategories(categories);
            return;
        }

        const filtered = categories.map((category) => {
            const filteredRoles = category.roles.filter((role) =>
                role.name.toLowerCase().includes(text)
            );

            return filteredRoles.length > 0
                ? { ...category, roles: filteredRoles }
                : null;
        }).filter(Boolean);

        setFilteredCategories(filtered);
    };

    const hideRoles = () => {
        setRolesHide(!isRolesHide)
    }

    const formatLang = () => {
        const formattedLanguages = languagesData
            .filter((lang) => lang.id && lang.level) // Только полностью заполненные языки
            .map((lang) => ({
                id: lang.id,
                level: { id: lang.level },
            }));
        console.log({ languages: formattedLanguages }); // Отправка данных на сервер
        return formattedLanguages
    };

    const formatLicense = () => {
        const formattedLicenses = licenseData.map((license) => ({ id: license }));
        console.log({ driver_license_types: formattedLicenses }); // Отправка данных на сервер
        return formattedLicenses
    };

    const formatSkills = () => {
        const formattedSkills = skills.map((skill) => ({ name: skill.name }));
        console.log({ key_skills: formattedSkills }); // Отправка данных на сервер
        return formattedSkills
    };

    console.log(selectedRoles)

    const handleSubmit = async () => {
        // Функция для валидации
        const validate = () => {
            let errors = [];

            // 1) Проверка professional_roles
            if (selectedRoles.length === 0) {
                errors.push("Не выбрана специализация сотрудника (professional_roles).");
            }

            // 2) Проверка billing_type
            if (!billing_type) {
                errors.push("Не выбран тариф (billing_type).");
            }

            // 3) Проверка type и custom_employer_name
            if (!type) {
                errors.push("Не выбран тип вакансии (type).");
            } else if (type === "anonymous" && !custom_employer_name.trim()) {
                errors.push("Введите название компании для анонимной вакансии (custom_employer_name).");
            }

            return errors;
        };

        // Получение списка ошибок
        const errors = validate();

        if (errors.length > 0) {
            setSnackbarMessage(errors.join("\n"));
            setSnackbarOpen(true); // Открыть Snackbar
            return; // Прекращаем выполнение, если есть ошибки
        }

        // Если валидация прошла, формируем данные для отправки
        const publishData = {
            accept_handicapped: body.accept_handicapped,
            accept_incomplete_resumes: body.accept_incomplete_resumes,
            accept_kids: false,
            address: null,
            allow_messages: true,
            branded_template: null,
            code: null,
            contacts: null,
            custom_employer_name: type === "anonymous" ? custom_employer_name.trim() : null,
            department: null,
            driver_license_types: formatLicense(),
            key_skills: formatSkills(),
            languages: formatLang(),
            professional_roles: [
                {
                    id: selectedRoles[0],
                },
            ],
            response_letter_required: body.response_letter_required,
            response_notifications: null,
            response_url: null,
            test: null,
            working_days: body.only_saturday_and_sunday
                ? [
                    {
                        id: body.only_saturday_and_sunday,
                    },
                ]
                : null,
            working_time_intervals: body.from_four_to_six_hours_in_a_day
                ? [
                    {
                        id: body.from_four_to_six_hours_in_a_day,
                    },
                ]
                : null,
            working_time_modes: body.start_after_sixteen
                ? [
                    {
                        id: body.start_after_sixteen,
                    },
                ]
                : null,
            billing_type: {
                id: billing_type,
            },
            manager: null,
            previous_id: null,
            type: {
                id: type,
            },
        };

        try {
            // Отправка данных
            const response = await publishToHH({
                publishData,
                company_id: localStorage.getItem('company_id'),
                vacancy_id: vacancyId,
            });
            console.log("Публикация успешна:", response);

            // Очистка данных формы
            setSkills([]);
            setLicenseData([]);
            setLanguagesData([{ id: "", level: "" }]);
            setOpenCategories({});
            setSelectedRoles([]);
            setSearchText("");
            setBillingType(null);
            setType(null);
            setCustomEmployerName("");
            setBody({
                only_saturday_and_sunday: false,
                from_four_to_six_hours_in_a_day: false,
                start_after_sixteen: false,
                accept_handicapped: false,
                accept_incomplete_resumes: false,
                response_letter_required: false
            });

            // Закрыть модалку
            closeModal();

            // Показать сообщение об успехе
            setSnackbarMessage("Публикация успешно завершена!");
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Ошибка при публикации:", error);
            setSnackbarMessage("Ошибка при публикации. Попробуйте еще раз.");
            setSnackbarOpen(true);
        }
    };




    console.log(billing_type)
    return (
        <>
            <Modal
                open={isModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "776px",
                        bgcolor: "background.paper",
                        borderRadius: "8px",
                        boxShadow: 24,
                        p: 4,
                        height: "80vh",
                        overflowY: "auto",
                    }}
                >
                    <Typography id="modal-title" variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                        Публикация на HeadHunter
                    </Typography>

                    <FormControl fullWidth sx={{ mb: 4 }}>
                        <FormLabel sx={{ fontWeight: 600, mb: 1 }}>
                            Специализация сотрудника <span style={{ color: '#FF3B30' }}>*</span>
                        </FormLabel>
                        <Typography
                            onClick={hideRoles}
                            id="modal-text"
                            variant="body1"
                            sx={{
                                mb: 2,
                                fontWeight: 500,
                                color: '#185399',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            {isRolesHide ? (
                                <>Скрыть <ExpandMore /></>
                            ) : (
                                <>Выбрать <ExpandLess /></>
                            )}
                        </Typography>

                        {!isRolesHide && (
                            <Box
                                sx={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    padding: 2,
                                    width: '451px',
                                    height: '336px',
                                    overflowY: 'scroll',
                                }}
                            >
                                <TextField
                                    placeholder="Быстрый поиск"
                                    fullWidth
                                    size="small"
                                    value={searchText}
                                    onChange={handleSearch}
                                    sx={{ mb: 2 }}
                                />
                                <RadioGroup
                                    value={selectedRoles[0] || ''}
                                    onChange={(e) => setSelectedRoles([e.target.value])}
                                >
                                    {filteredCategories.map((category) => (
                                        <Box key={category.id}>
                                            <ListItemButton onClick={() => toggleCategory(category.id)}>
                                                <ListItemText primary={category.name} />
                                                {openCategories[category.id] ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                            <Collapse in={openCategories[category.id]} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {category.roles.map((role) => (
                                                        <ListItem key={role.id} sx={{ pl: 4 }}>
                                                            <FormControlLabel
                                                                value={role.id}
                                                                control={
                                                                    <Radio
                                                                        sx={{
                                                                            color: '#599D15',
                                                                            '&.Mui-checked': { color: '#599D15' },
                                                                        }}
                                                                    />
                                                                }
                                                                label={role.name}
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Collapse>
                                        </Box>
                                    ))}
                                </RadioGroup>
                            </Box>
                        )}
                    </FormControl>


                    <FormControl component="fieldset" fullWidth sx={{ mb: 4 }}>
                        <FormLabel sx={{ fontWeight: 500, mb: 1, color: '#151813', fontSize: '16px' }}>Тариф <span style={{ color: '#FF3B30' }}>*</span></FormLabel>
                        <RadioGroup
                            aria-label="tariff"
                            name="tariff"
                            onChange={(e) => setBillingType(e.target.value)}
                        >
                            <FormControlLabel value="free" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Бесплатный" />
                            <FormControlLabel value="standard" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Стандарт" />
                            <FormControlLabel value="standard_plus" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Стандарт плюс" />
                            <FormControlLabel value="premium" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Премиум" />
                        </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset" fullWidth sx={{ mb: 4 }}>

                        <FormLabel sx={{ fontWeight: 500, mb: 1, color: '#151813', fontSize: '16px' }}>Тип вакансии <span style={{ color: '#FF3B30' }}>*</span></FormLabel>
                        {type === 'anonymous' && (
                            <TextField
                                value={custom_employer_name}
                                onChange={(e) => setCustomEmployerName(e.target.value)}
                                placeholder="Введите название компании"
                                fullWidth
                                sx={{
                                    marginTop: 2,
                                    marginBottom: 1,
                                    backgroundColor: "#F8F9F9",
                                    borderRadius: "8px",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        height: "52px",
                                        "& fieldset": {
                                            borderColor: "transparent",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#BDBDBD",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#599D15",
                                        },
                                    },
                                }}
                            />
                        )}
                        <span style={{ fontSize: '14px', fontWeight: 400, color: '#ADB5BD', marginBottom: '16px' }}>Пример: Крупный банк РК</span>
                        <RadioGroup
                            aria-label="tariff"
                            name="tariff"
                            onChange={(e) => setType(e.target.value)}
                        >
                            <FormControlLabel value="direct" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Рекламная" />
                            <FormControlLabel value="anonymous" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Анонимная" />
                            <FormControlLabel value="closed" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Закрытая" />
                            <FormControlLabel value="open" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Открытая" />
                        </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset" fullWidth sx={{ mb: 4 }}>
                        <FormLabel sx={{ fontWeight: 500, mb: 1, color: '#151813', fontSize: '16px' }}>Режим работы</FormLabel>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={body.only_saturday_and_sunday || false}
                                        onChange={(e) =>
                                            setBody((prev) => ({
                                                ...prev,
                                                only_saturday_and_sunday: e.target.checked,
                                            }))
                                        }
                                        sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }}
                                    />
                                }
                                label="По субботам и воскресеньям"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={body.from_four_to_six_hours_in_a_day || false}
                                        onChange={(e) =>
                                            setBody((prev) => ({
                                                ...prev,
                                                from_four_to_six_hours_in_a_day: e.target.checked,
                                            }))
                                        }
                                        sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }}
                                    />
                                }
                                label="Можно сменами по 4-6 часов в день"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={body.start_after_sixteen || false}
                                        onChange={(e) =>
                                            setBody((prev) => ({
                                                ...prev,
                                                start_after_sixteen: e.target.checked,
                                            }))
                                        }
                                        sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }}
                                    />
                                }
                                label="С началом дня после 16:00"
                            />
                        </Box>
                    </FormControl>

                    <FormControl component="fieldset" fullWidth sx={{ mb: 4 }}>
                        <FormLabel sx={{ fontWeight: 500, mb: 1, color: '#151813', fontSize: '16px' }}>Кто и как может откликаться</FormLabel>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={body.accept_handicapped || false}
                                        onChange={(e) =>
                                            setBody((prev) => ({
                                                ...prev,
                                                accept_handicapped: e.target.checked,
                                            }))
                                        }
                                        sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }}
                                    />
                                }
                                label="С инвалидностью"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={body.accept_incomplete_resumes || false}
                                        onChange={(e) =>
                                            setBody((prev) => ({
                                                ...prev,
                                                accept_incomplete_resumes: e.target.checked,
                                            }))
                                        }
                                        sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }}
                                    />
                                }
                                label="С неполным резюме"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={body.response_letter_required || false}
                                        onChange={(e) =>
                                            setBody((prev) => ({
                                                ...prev,
                                                response_letter_required: e.target.checked,
                                            }))
                                        }
                                        sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }}
                                    />
                                }
                                label="Только с сопроводительным письмом"
                            />
                        </Box>
                    </FormControl>

                    <LanguageSection languagesData={languagesData}
                        setLanguagesData={setLanguagesData} />


                    <DriverLicenseSection licenseData={licenseData}
                        setLicenseData={setLicenseData} />

                    <SkillsSection skills={skills} setSkills={setSkills} />



                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={closeModal} sx={{ mr: 2 }}>
                            Отмена
                        </Button>
                        <Button onClick={handleSubmit} variant="contained" color="primary">
                            Опубликовать
                        </Button>
                    </Box>
                </Box>
            </Modal >
            {/* Snackbar для отображения ошибок */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarMessage.includes("успешно") ? "success" : "error"} // success для зеленого, error для красного
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default PublicationModalHH;
