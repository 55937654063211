import { format } from "date-fns";
import ru from "date-fns/locale/ru";

/**
 * 
 * @param {String} dateString - date in string format
 * @param {String} format - preferred format
 * @returns {String} - formatted date
 */
export const formatDate = (dateString, format = "DD.MM.YYYY") => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (isNaN(date)) return ""; // Handle invalid date

    const replacements = {
        DD: String(date.getDate()).padStart(2, "0"),
        MM: String(date.getMonth() + 1).padStart(2, "0"),
        YYYY: date.getFullYear(),
        YY: String(date.getFullYear()).slice(-2),
        HH: String(date.getHours()).padStart(2, "0"),
        mm: String(date.getMinutes()).padStart(2, "0"),
        ss: String(date.getSeconds()).padStart(2, "0"),
    };

    return format.replace(/DD|MM|YYYY|YY|HH|mm|ss/g, (match) => replacements[match] || match);
};

export const getTimeStampFomatted = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    
    if (
        date.getDate() === now.getDate() &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear()
    ) {
        return `Сегодня в ${format(date, "HH:mm", { locale: ru })}`;
    } else if (
        date.getDate() === now.getDate() - 1 &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear()
    ) {
        return `Вчера в ${format(date, "HH:mm", { locale: ru })}`;
    } else {
        return format(date, "d MMMM, HH:mm", { locale: ru });
    }
};