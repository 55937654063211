import React, { useState, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box, Typography } from '@mui/material';

// Добавляем кастомные размеры шрифта
const Size = Quill.import('formats/size');
Size.whitelist = ['16px', '28px'];
Quill.register(Size, true);

// Кастомный тулбар
function CustomToolbar() {
    return (
        <div id="toolbar">
            <select className="ql-size" defaultValue="16px" aria-label="Выбор размера шрифта">
                <option value="16px">16px</option>
                <option value="28px">28px</option>
            </select>
            <button className="ql-bold" type="button" aria-label="Жирный" />
            <button className="ql-italic" type="button" aria-label="Курсив" />
            <button className="ql-underline" type="button" aria-label="Подчёркнутый" />
            <button className="ql-link" type="button" aria-label="Ссылка" />
            <span className="ql-formats">
                <button className="ql-align" value="" aria-label="Выравнивание по левому краю" />
                <button className="ql-align" value="center" aria-label="Выравнивание по центру" />
                <button className="ql-align" value="right" aria-label="Выравнивание по правому краю" />
                <button className="ql-align" value="justify" aria-label="Выравнивание по ширине" />
            </span>
            <button className="ql-list" value="ordered" aria-label="Нумерованный список" />
            <button className="ql-list" value="bullet" aria-label="Маркированный список" />
        </div>
    );
}

function VacancyEditor({ text, setText }) {
    const modules = {
        toolbar: {
            container: '#toolbar', // Подключаем кастомный тулбар
        },
    };

    const formats = [
        'size',
        'bold',
        'italic',
        'underline',
        'link',
        'align',
        'list', // Добавляем формат списка
    ];

    const handleTextChange = (value) => {
        setText(value);
    };

    useEffect(() => {
        const quillEditor = document.querySelector('.ql-editor');
        if (quillEditor) {
            quillEditor.style.fontSize = '16px';
        }
    }, []);

    return (
        <Box elevation={3} style={{ marginTop: 4, marginBottom: 16, width: '100%' }}>
            <CustomToolbar />
            <ReactQuill
                theme="snow"
                value={text}
                onChange={handleTextChange}
                modules={modules}
                formats={formats}
                style={{ height: '390px' }}
            />
        </Box>
    );
}

export default VacancyEditor;
